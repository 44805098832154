import React, { useState, useEffect } from 'react';
import './../css/AlertPopUp.css'; 
import { Button } from '@chakra-ui/react';

const Alert = ({ message, type, onClose }) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
      onClose();
    }, 3000); // Define o tempo de exibição do alerta (em milissegundos)

    return () => {
      clearTimeout(timer);
    };
  }, [onClose]);

  const handleClick = () => {
    setIsVisible(false);
    onClose();
  };

  return isVisible ? (
    <div className='corpo'>
        
        <div className={`alert ${type}`}>
        
        <p>{message}</p>
        <Button mt={2} onClick={handleClick}>ok</Button>
        </div>
    </div>
  ) : null;
};

export default Alert;
