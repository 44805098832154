import './App.css'; 
import axios from 'axios';
import { useEffect, useRef, useState } from 'react'; 

import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Box,  Button, Card, CardBody, CardFooter, Center, Container, Drawer, DrawerContent, DrawerOverlay, Flex, Heading, Link, Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay,Spinner,Text, useMediaQuery} from "@chakra-ui/react";
import { Router, Routes, useNavigate, useParams } from 'react-router-dom';  
import Header from './componentes/Header';
import Footer from './componentes/Footer';   

import { Pagination, Scrollbar, A11y, Navigation, EffectCoverflow } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import './css/Slider.css';

const apiUrl = process.env.REACT_APP_API_URL; 
const apiUrlWEB = process.env.REACT_APP_API_URL_WEB;



const CarouselBanner = ({ images, imagesdefault }) => {
  
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [empresa_tenant_id, setEmpresatenant_id] = useState('');
  const { tenant_id } = useParams();


  const fetchEmpresa = async () => { 
    const response = await fetch(`${apiUrl}/${tenant_id}/empresa`);
    const data = await response.json();    
    setEmpresatenant_id(data[0].id); 
  }  
  useEffect(() => {
    const loadImage = async (src) => {
      return new Promise((resolve) => {
        const img = new Image();
        img.src = src;
        img.onload = resolve;
      });
    };

    const loadImages = async () => {
      await Promise.all(images.map((image) => loadImage(image.url)));
      setImagesLoaded(true);
    };

    loadImages();
  }, [images]);

  useEffect(() => {
    fetchEmpresa();
  }, []);

  const centerImage = () => {
    const carousel = document.querySelector('.swiper_container');
    const images = document.querySelectorAll('.swiper-wrapper');

    if (carousel && images.length > 0) {
      const firstImage = images[0];
      const carouselWidth = carousel.offsetWidth;
      const imageWidth = firstImage.offsetWidth;

      const offset = (carouselWidth - imageWidth) / 2;

      images.forEach((image) => {
        image.style.left = `${offset}px`;
      });
    }
  };

 
  const settings = {
    dots: true,      // Remove as bolinhas de navegação 
    infinite: true,
    speed: 400,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,   // Habilita o autoplay
    autoplaySpeed: 3000,  // Define o intervalo de tempo entre os slides (em milissegundos)
  };
   useEffect(() => {
    if (imagesLoaded) {
      centerImage();
    }
  }, [imagesLoaded]);



  return (
    <Box position="relative" p={0} textAlign="center">
      {!imagesLoaded && (
        <Box position="absolute" top="50%" left="50%" transform="translate(-50%, -50%)">
          <Spinner size="lg" />
        </Box>
      )} 
      {!imagesdefault &&
              <Slider {...settings}> 
              {images.map((image, index) => (
                <div key={index}>
                  {imagesdefault ?
                  <img
                    className="full-width-image"
                    src={'/BANNER.jpg'}
                    alt={`Imagem ${index + 1}`}
                    onLoad={() => setImagesLoaded(true)}
                    style={{
                      display: imagesLoaded ? 'block' : 'none',
                      objectFit: 'cover', // Faz a imagem preencher o contêiner mantendo as proporções
                      width: '100%', 
                    }}
                  />
                  :
                  <img
                  className="full-width-image"
                  src={`${apiUrlWEB}/uploads/empresa_app/${empresa_tenant_id}/${image}`} 
                  alt={`Imagem ${index + 1}`}
                  onLoad={() => setImagesLoaded(true)}
                  style={{
                    display: imagesLoaded ? 'block' : 'none',
                    objectFit: 'cover', // Faz a imagem preencher o contêiner mantendo as proporções
                    width: '100%', 
                  }}
                /> 
                }
                
                </div>
              ))}
            </Slider>

        }
    </Box>
  );
};

// const Servicos = ({ servicos }) => {
//   const { tenant_id } = useParams();

//   const navigate = useNavigate();  
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [selectedService, setSelectedService] = useState(null);
//   const [agendamentos, setAgendamentos] = useState(JSON.parse(localStorage.getItem('agendamentos')) || []);
 
//   const openModal = (servico) => {
//     setSelectedService(servico);
//     setIsModalOpen(true);
//   };

//   const closeModal = () => {
//     setSelectedService(null);
//     setIsModalOpen(false);
//   };
    

//   const [isDragging, setIsDragging] = useState(false);
//   const itemsToShow = 2; 
 
//   const handleAgendarClick = async (servico) => {  
//     const response = await fetch(`${apiUrl}/${tenant_id}/disponibilidadeunic`);
//     await response.json().then((result) => {
//       if (result.length > 0) {  
//          navigate(`/${tenant_id}/agendar`, { state: { servico,  datainicial: result[0].data } });  
//       }
//     });  
//   };  

//   const sliderNavStyle = {
//     display: 'flex',
//     justifyContent: 'center',
//     alignItems: 'center',
//     marginTop: '10px', // Adjust the margin as needed
//   }; 
  
//   const [images, setImages] = useState([]); 
//   // Simulação de busca de imagens assíncrona (substitua isso pela sua lógica de busca real)
//   useEffect(() => {
//     const fetchImages = async () => {
//       // Simulação de um tempo de carregamento
//       await new Promise((resolve) => setTimeout(resolve, 1000));

//       // Simulação de imagens
//       setImages([
//         { url: '/BANNER.jpg' },
//         { url: '/BANNER.jpg' },
//         // Adicione mais imagens conforme necessário
//       ]);
//     };

//     fetchImages();
//   }, []);

 
//   const settings = {
//     dots: false,      // Remove as bolinhas de navegação 
//     infinite: true,
//     speed: 0,
//     arrows: false,
//     swipe: true,
//     draggable: true,
//     slidesToShow: 3,
//     slidesToScroll: 1,
//     touchMove: true,
//     autoplay: false,   // Habilita o autoplay
//     autoplaySpeed: 2000,  // Define o intervalo de tempo entre os slides (em milissegundos)
//     centerMode: true,
//     beforeChange: () => setIsDragging(true),
//     responsive: [
//            {
//              breakpoint: 768, // Adapte as configurações para telas menores que 768 pixels de largura (por exemplo, dispositivos móveis)
//              settings: {
//                centerPadding: '10%', // Ajuste conforme necessário
//                slidesToShow: 1, 
//                slidesToScroll: 1, 
//                adaptiveHeight: true,  
//                centerMode: true,
//                swipeToSlide: true,
//                focusOnSelect: false, 
//                swipeThreshold: 5,
//              },
//            },
//     ],
//   };

//   const [datateste, setData] = useState([]);

//   useEffect(() => {
//     fetch("https://jsonplaceholder.typicode.com/posts/")
//       .then((res) => res.json())
//       .then((res) => setData(res));
//   }, []);

//   const [isDrawerOpen, setIsDrawerOpen] = useState(false);

//   return (
//     <>  
//    <Header agendamentos={agendamentos} /> 
//     <Box  >
//       <Box   mb={7}   textAlign="center">
//          <CarouselBanner images={images}  />
//       </Box>
//       <Box textAlign="center"> 
//        <Text
//         fontSize={{ base: 'md', md: '2xl' }} 
//         color="gray.600"
//         textAlign="center"
//         mb={4}  
//        >
//           Sua frase personalizada aqui
//        </Text>
//       </Box>
//       <Heading 
//       mx="auto"  
//       my="4" 
//       p={1.5} 
//       backgroundColor={'#53D0C9'}
//       color={'white'}
//       borderRadius={100}
//       as="h1"
//       mb={4}
//       fontSize={'18'}>
//         SERVIÇOS
//       </Heading> 
//       <Box  >
 
//       <Container
//         py={8}
//         px={0}
//         maxW={{
//           base: "50%",
//           sm: "35rem",
//           md: "43.75rem",
//           lg: "57.5rem",
//           xl: "75rem",
//           xxl: "87.5rem"
//         }}
//       >
//         <ChakraCarousel gap={32}>
//         {servicos.map((servico, index) => (
//             <Flex
//               key={index}
//              // boxShadow="rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px"
//               justifyContent="space-between"
//               flexDirection="column"
//               overflow="hidden"
//               color="gray.300"
//               bg="base.d100"
//               rounded={5}
//               flex={1}
//               p={5}
            
//             > 
//               <Box  
//                 background={`url('${apiUrlWEB}/uploads/${servico.empresa_id}/${servico.foto}')`} 
//                 backgroundColor={'gray.500'}
//                 backgroundPosition="center"
//                 backgroundRepeat="no-repeat"
//                 backgroundSize="cover"
//                 borderRadius={20}
//                 style={{ 
//                   objectFit: 'cover',
//                   position: 'relative',
//                   borderWidth: '1px',
//                   borderRadius: 'lg',
//                   overflow: 'hidden',
//                   height: '260px',
//                 }}
//               > 
//               </Box> 

//               <Box    
//                 textAlign="center"
//                 color="black"  
//               >
//                 <Heading as="h3" fontSize={{ base: '15px', md: 'lg' }} mb={2}>
//                   {servico.nome}
//                 </Heading>
//               </Box> 
//               <Flex justifyContent="center"> 
//                 <Button  
//                   backgroundColor={'#53D0C9'}
//                   color={'white'}
//                   borderRadius={100}
//                    size="sm"
//                    mb={1}
//                    _hover={{ bg: 'teal.500' }}
//                    _focus={{ boxShadow: 'outline' }}
//                    onClick={() => { 
//                        openModal(servico); 
//                    }}
//                 >
//                   AGENDAR
//                 </Button>
//               </Flex>
//             </Flex>
//           ))}
//         </ChakraCarousel>
//       </Container> 
  
    
//     <Link
//       onClick={() => setIsDrawerOpen(true)}
//       fontWeight="bold" // Deixa o texto em negrito
//       textDecoration="underline" // Adiciona sublinhado
//       fontSize={{ base: 'xs', md: '2xl' }}
//     >
//       VEJA TODOS
//     </Link>

     

//     </Box>
//     <Drawer isOpen={isDrawerOpen} placement="bottom" onClose={() => setIsDrawerOpen(false)}>
//     <DrawerOverlay />
//     <DrawerContent
//         bg="#fff"
//         color="black"
//         height="90%" // Defina a altura desejada
//         display="flex"
//         flexDirection="column"
//         alignItems="center"
//         justifyContent="center"
//         position="relative" // Adicione essa propriedade para alinhar corretamente
//         p={5}
//         overflow="auto"
//         // pt="1200px"
//     >

//       <Box pb={120*servicos.length+40}></Box>
//       <Box cursor="pointer" ml="0" onClick={() => setIsDrawerOpen(false)} marginLeft="auto" marginBottom="1"   fontSize='40px' color="#53D0C9">
//           x
//         </Box>  


//         {servicos.map((servico, index) => (
// <Card
//   direction={{ base: 'row', sm: 'row' }}
//   overflow='hidden'
//   variant='outline'
//   marginBottom="4"
//   minH="120px"
//   bg="#000000"
//   color="white"
//   p={2}
//   minWidth={{ base: '95vw', sm: '470px' }}
// >
  
       

// <img    src={`${apiUrlWEB}/uploads/${servico?.empresa_id}/${servico?.foto}`}  alt="Logo"  objectFit="cover" borderRadius="md"  style={{
//                 width: '100px', 
//               }}

//             onError={(e) => {
//               e.target.src = 'https://cdn-icons-png.flaticon.com/512/190/190420.png';
//             }}
//           />

  
//     <CardBody >
//       <Heading fontSize='12px' maxW="100px" 
//       maxH="54px"
//       overflow="hidden"
//       // isTruncated
//       textOverflow="ellipsis"
//       >{servico.nome}</Heading>

//       <Text py='2' fontSize='10px'>
//       Tempo: {servico.tempo_servico ? `${servico.tempo_servico}min` : "Não informado"}
//       </Text>
//     </CardBody>

//     <CardFooter p={2}>
//   <Flex direction="column" align="center" justify="center" >
    
//       <Text marginBottom="4" fontWeight="bold" >R$ {Number(servico.valor_cliente).toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</Text>
//       <Button variant='solid' colorScheme='blue' backgroundColor={'#53D0C9'}
//                   color={'white'}
//                   borderRadius={100}
//                    size="sm"
//                    mb={1}
//                    _hover={{ bg: 'teal.500' }}
//                    _focus={{ boxShadow: 'outline' }}
//                    onClick={() => { 
//                        openModal(servico); 
//                    }}>
//         Agendar
//       </Button>
//   </Flex>
// </CardFooter>

  
// </Card>

// ))}


 
//     </DrawerContent>
// </Drawer>

 
//     <Modal  isOpen={isModalOpen} onClose={closeModal} size={{ base: "full", xl: "xl" }}>
//       <ModalOverlay />
//       <ModalContent> 
//       {/* <ModalCloseButton 
//       alignContent={'flex-end'} 
//       w={{ base: '180%', md: '20px' }}
//       >
//         <Text
//           fontWeight="bold"
//           fontSize={{ base: "2xl", md: "xl", lg: "2xl" }}
//           fontFamily="arial"
//           color="#4BD5C6"
//         >
//          X
//         </Text>
//       </ModalCloseButton> */}
//        <ModalCloseButton>
//          <Text
//          fontWeight={'bold'}
//          fontSize={{ base: "2xl", md: "xl", lg: "2xl" }}
//          fontFamily={'arial'} 
//          color={'#4BD5C6'}
//          >
//           X
//          </Text>
//         </ModalCloseButton>
//         <ModalBody pt={{ base: "30%", xl: "10%" }} textAlign={{ base: "center", xl: "left" }}> 

//           <Box 
//           backgroundColor={'#000000'}
//           p={{ base: "6", md: "6" }}
//           borderRadius={20} 
//           >  
//             <Center>
//               <img  
//                 src={`${apiUrlWEB}/uploads/${selectedService?.empresa_id}/${selectedService?.foto}`} 
//                 alt="Foto" 
//                 boxSize="100%"
//                 objectFit="cover" 
//                 style={{'borderRadius': '20px', 'maxHeight': '400px', 'alignItems': 'center'}}
//                 onError={(e) => {
//                   e.target.src = 'https://png.pngtree.com/512/190/190420.png';
//                 }}
//               />
//             </Center>
//             <Center>
//               <Text color={'white'} fontSize={{ base: "2xl", md: "xl", lg: "2xl" }}>
//                 <b> {selectedService && selectedService.nome}</b>
//               </Text> 
//             </Center>
//             <Center>
//               <Text  color={'white'} fontSize={{ base: "lg", md: "xl", lg: "2xl" }}>
//                 Tempo: {selectedService && selectedService.tempo_servico} min
//               </Text> 
//             </Center>
//             <Center>
//               <Text  color={'white'} fontSize={{ base: "4xl", md: "xl", lg: "2xl" }}>
//                  R$ {selectedService && (selectedService.valor_cliente).toLocaleString('pt-BR', { minimumFractionDigits: 2 })} 
//               </Text>
//             </Center>
            
//           </Box>  
//             <Center>
//               <Button
//               backgroundColor={'#4CD5C2'}
//               size="sm"
//               borderRadius={100}
//               color={'white'}
//               mb={2}
//               mt={-4}
//               width={{ base: '50%', md: '50%' }}
//               _hover={{ bg: 'teal.500' }}
//               _focus={{ boxShadow: 'outline' }}
//               fontSize={{ base: "2xl", md: "xl", lg: "2xl" }}
//               p={{ base: "7%", md: "4", lg: "6" }}
//               onClick={() => selectedService && handleAgendarClick(selectedService)}   
//             >
//               AGENDAR
//             </Button> 
//           </Center>
//         </ModalBody> 
//       </ModalContent>
//     </Modal>
//     </Box> 
//     <div style={{ marginTop: '40px' }}>
//   <Footer agendamentos={agendamentos} />
  
// </div>
//     </>
//   );
// };



const NewCarrossel  = ({ servicos })  => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedService, setSelectedService] = useState(null);
  const [agendamentos, setAgendamentos] = useState(JSON.parse(localStorage.getItem('agendamentos')) || []); 
  const [cores, setCores] = useState([]);
  const openModal = (servico) => {
    setSelectedService(servico);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedService(null);
    setIsModalOpen(false);
  };

  const { tenant_id } = useParams();
  const navigate = useNavigate();

  
  const fetchConfigEmpresa = async () => {
    const response = await fetch(`${apiUrl}/${tenant_id}/buscarconfigappempresa`);
    const data = await response.json();   
    setCores(JSON.parse(data[0].cores));

  }
  
  const handleAgendarClick = async (servico) => {  
    const response = await fetch(`${apiUrl}/${tenant_id}/disponibilidadeunic`);
    await response.json().then((result) => {
      if (result.length > 0) {  
         navigate(`/${tenant_id}/agendar`, { state: { servico,  datainicial: result[0].data } });  
      }
    });  
  }; 

  const [isDrawerOpen, setIsDrawerOpen] = useState(false); 
  const [images, setImages] = useState([]); 
  const [imagesdefault, setImagesdefault] = useState(true); 
  const [frase, setFrase] = useState('');

  
  useEffect(() => {  
    const fetchEmpresaApp = async () => { 
        const response = await fetch(`${apiUrl}/${tenant_id}/empresaapp`);
        const data = await response.json();   
        setFrase(data[0].frase_personalizada);  
        if((JSON.parse(data[0].banners)).length > 0){ 
          setImages(JSON.parse(data[0].banners)); 
          setImagesdefault(false)
        }else{
          setImagesdefault(true) 
          setImages([
            { url: '/BANNER.jpg' },
            { url: '/BANNER.jpg' },
            // Adicione mais imagens conforme necessário
          ]);
        }  
    } 
    
    fetchEmpresaApp();
    fetchConfigEmpresa();
  }, []);

  const [isMobile] = useMediaQuery("(max-width: 768px)"); 

  const scaleValue = isMobile ? 0.5 : 1;

  const style = {
    transform: `scale(${scaleValue})`,
    width: `${100 / scaleValue}%`,  // Ajuste proporcional da largura
    height: `${100 / scaleValue}%`, // Ajuste proporcional da altura
    transformOrigin: 'top left',    // Define o ponto de origem da transformação
  };

  const swiperRef = useRef(null);
 
  useEffect(() => {
    if (swiperRef.current) {
      swiperRef.current.swiper.update();
    }
  }
  , [isMobile]);
  
  return (
    <>
    <Modal  isOpen={isModalOpen} onClose={closeModal} size={{ base: "full", xl: "xl" }}>
      <ModalOverlay />
      <ModalContent>  
       <ModalCloseButton>
         <Text
         fontWeight={'bold'}
         fontSize={{ base: "2xl", md: "xl", lg: "2xl" }}
         fontFamily={'arial'} 
         color={'#4BD5C6'}
         >
          X
         </Text>
        </ModalCloseButton>
        <ModalBody pt={{ base: "30%", xl: "10%" }} textAlign={{ base: "center", xl: "left" }}
        backgroundColor={cores.cor_fundo || "#000000"} 
        > 

          <Box 
          
          p={{ base: "6", md: "6" }}
          borderRadius={20} 
          >  
            <Center>
              <img  
                src={`${apiUrlWEB}/uploads/${selectedService?.empresa_id}/${selectedService?.foto}`} 
                alt="Foto"    
                style={{'borderRadius': '20px', 'maxHeight': '400px', 'alignItems': 'center', 'objectFit': 'cover' }}
                onError={(e) => {
                  e.target.src = 'https://png.pngtree.com/512/190/190420.png';
                }}
              />
            </Center>
            <Center>
              <Text color={cores.cor_textofundo || "white"}  fontSize={{ base: "2xl", md: "xl", lg: "2xl" }}>
                <b> {selectedService && selectedService.nome}</b>
              </Text> 
            </Center>
            <Center>
              <Text  color={cores.cor_textofundo || "white"}  fontSize={{ base: "lg", md: "xl", lg: "2xl" }}>
                Tempo: {selectedService && selectedService.tempo_servico} min
              </Text> 
            </Center>
            <Center>
              <Text  color={cores.cor_textofundo || "white"}  fontSize={{ base: "4xl", md: "xl", lg: "2xl" }}>
                 R$ {selectedService && (selectedService.valor_cliente).toLocaleString('pt-BR', { minimumFractionDigits: 2 })} 
              </Text>
            </Center>
            
          </Box>  
            <Center>
              <Button
              backgroundColor={cores.cor_botoes || '#000000'}
              size="sm"
              borderRadius={100}
              color={cores.cor_texto_botoes || 'white'} 
              mb={2}
              mt={-4}
              width={{ base: '50%', md: '50%' }}
              _hover={{ bg: 'teal.500' }}
              _focus={{ boxShadow: 'outline' }}
              fontSize={{ base: "2xl", md: "xl", lg: "2xl" }}
              p={{ base: "7%", md: "4", lg: "6" }}
              onClick={() => selectedService && handleAgendarClick(selectedService)}   
            >
              AGENDAR
            </Button> 
          </Center>
        </ModalBody> 
      </ModalContent>
    </Modal>
    <Header agendamentos={agendamentos} />
      <Box   mb={-2}   textAlign="center">
         <CarouselBanner images={images} imagesdefault={imagesdefault} />
      </Box> 
      <Box textAlign="center"> 
       <Text 
        mt={6}
        fontSize={{ base: 'md', md: '2xl' }}  
        color={cores.cor_textofundo || 'gray.600'}
        textAlign="center"
        mb={-2}  
       > 
           {frase} 
       </Text>
      </Box>
    <div className="container carrossel"  style={style} > 
    <Heading 
      mx="auto"  
      my="4" 
      p={1.5}
      w={{base:'170px' , md:'170px'}} 
      backgroundColor={cores.cor_botoes || '#53D0C9'}
      color={cores.cor_texto_botoes || 'white'}
      borderRadius={50}
      as="h1"
      mb={-3}
      fontSize={{base:'26px', md:'25px'}}
      onClick={() => setIsDrawerOpen(true)}
      >
        SERVIÇOS
      </Heading> 
      <div className="2" key={1}>
        <Swiper
           ref={swiperRef}
          effect={'coverflow'}
          grabCursor={true}
          centeredSlides={true}
          loop={true}
          slidesPerView={'auto'}
          coverflowEffect={{
            rotate: 0,
            stretch: 0,
            depth: 100,
            modifier: 4,
          }}
          pagination={{ el: '.swiper-pagination', clickable: true }}
          navigation={{
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
            clickable: true,
          }}
          modules={[EffectCoverflow, Pagination, Navigation]}
          className="swiper_container" 
        > 
  {servicos.map((servico, index) => (
          <SwiperSlide >
            <img  
            src={`${apiUrlWEB}/uploads/${servico.empresa_id}/${servico.foto}`}  alt="slide_image"  style={{ objectFit: 'cover'}} 
            onClick={() => { 
              openModal(servico); 
          }}
          onError={(e) => {
            e.target.src = 'https://l3conecta.com/storage/uploads/1b91df82-88f0-4f83-937b-1cdddb456759/istockphoto-1147544807-612x612.jpg';
          }}

            />
            <Box key={index}
                  textAlign="center"
                  color={cores.cor_textofundo || 'black'}
                >
                  <Heading key={index+"1"} as="h3" fontSize={{ base: '25px', md: '20px' }} mb={2}>
                    {servico.nome}
                  </Heading>
                </Box> 
                <Flex key={index+"1"} justifyContent="center"> 
                  <Button   
                    backgroundColor={cores.cor_botoes || '#53D0C9'} 
                    color={cores.cor_texto_botoes || 'white'}  
                    borderRadius={100}
                    size="sm"
                    fontSize={{ base: "3xl", md: "xl"}}
                    mb={1}
                    _hover={{ bg: 'teal.500' }}
                    _focus={{ boxShadow: 'outline' }}
                    onClick={() => { 
                        openModal(servico); 
                    }}
                  >
                    AGENDAR
                  </Button>
                </Flex>
          </SwiperSlide>
  ))}


          

          {/* <div className="slider-controler">
            <div className="swiper-button-prev slider-arrow">
              <ion-icon name="arrow-back-outline"></ion-icon>
            </div>
            <div className="swiper-button-next slider-arrow">
              <ion-icon name="arrow-forward-outline"></ion-icon>
            </div>
            <div className="swiper-pagination"></div>
          </div> */}
        </Swiper>
      </div>
    </div> 
    <Link
      onClick={() => setIsDrawerOpen(true)}
      fontWeight="bold" // Deixa o texto em negrito
      textDecoration="underline" // Adiciona sublinhado
      fontSize={{ base: 'xs', md: '2xl' }}
      color={cores.cor_textofundo || 'white'}
    >
      CATÁLOGO DE SERVIÇOS
    </Link>  
    <br></br><br></br><br></br><br></br> 
    <Drawer isOpen={isDrawerOpen} placement="bottom" onClose={() => setIsDrawerOpen(false)}>
    <DrawerOverlay />
    <DrawerContent
        bg="#fff"
        color="black"
        height="90%" // Defina a altura desejada
        display="block"
        position="relative" // Adicione essa propriedade para alinhar corretamente
        p={0}
        overflow="auto"
        // pt="1200px"
    >
      <Flex 
    flexDirection="column"
    alignItems="center">

      <Box cursor="pointer" position="relative" top="0" right="0" onClick={() => setIsDrawerOpen(false)} marginLeft="auto" marginBottom="1"   fontSize='40px' color="#53D0C9">x</Box>

      


        {servicos.map((servico, index) => (
            <Card
              direction={{ base: 'row', sm: 'row' }}
              overflow='hidden'
              variant='outline'
              marginBottom="4"
              minH="120px"
              bg={cores.cor_fundo || "#000000"}
              color={cores.cor_textofundo || "white"}
              p={2}
              minWidth={{ base: '95vw', sm: '470px' }}
            >
  
        
            <img    src={`${apiUrlWEB}/uploads/${servico?.empresa_id}/${servico?.foto}`}  alt="Logo"  objectFit="cover" borderRadius="md"  style={{
                            width: '100px', 
                          }}

                        onError={(e) => {
                          e.target.src = 'https://l3conecta.com/storage/uploads/1b91df82-88f0-4f83-937b-1cdddb456759/istockphoto-1147544807-612x612.jpg';
                        }}
                      />

  
                <CardBody >
                  <Heading fontSize='12px' maxW="100px" 
                  maxH="54px"
                  overflow="hidden"
                  // isTruncated
                  textOverflow="ellipsis"
                  >{servico.nome}</Heading>

                  <Text py='2' fontSize='10px'>
                  Tempo: {servico.tempo_servico ? `${servico.tempo_servico}min` : "Não informado"}
                  </Text>
                </CardBody>

  <CardFooter p={2}>
  <Flex direction="column" align="center" justify="center" >
    
      <Text marginBottom="4" fontWeight="bold" >R$ {Number(servico.valor_cliente).toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</Text>
      <Button variant='solid' colorScheme='blue' 
                  backgroundColor={cores.cor_botoes || '#53D0C9'}
                  color={cores.cor_texto_botoes || 'white'}
                  borderRadius={100}
                   size="sm"
                   mb={1}
                   _hover={{ bg: 'teal.500' }}
                   _focus={{ boxShadow: 'outline' }}
                   onClick={() => { 
                       openModal(servico); 
                   }}>
        Agendar
      </Button>
  </Flex>
</CardFooter>

  
</Card>

))}

</Flex>


 
    </DrawerContent>
</Drawer>

    <Footer agendamentos={agendamentos} />
    </>
    
  );
}


function App() {
  const navigate = useNavigate();   
  const apiUrl = process.env.REACT_APP_API_URL;  
  const { tenant_id } = useParams();
  const [servicos, setServicos] = useState([]);
  const [onlineapp, setOnlineapp] = useState(false);

  async function getServicos() {
    try {
      const response = await axios.get(`${apiUrl}/${tenant_id}/servicos`);  
      setServicos(response.data); 
      setOnlineapp(true);
    } catch (error) {
      navigate('/erro');
      return;
    } 
  }

  useEffect(() => {
    getServicos();
  }, []);

  return (
    <>
    {onlineapp ? (
      <div className="App">
        <NewCarrossel servicos={servicos} />
      </div>
      ) : null}
    </>
  );

}

 

export default App;