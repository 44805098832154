// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
 

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Seletor de classe para os dots */
.slick-dots li button::before {
  color: silver !important; /* Substitua 'red' pela cor desejada para os dots */
  font-size: 10px !important; /* Substitua '10px' pelo tamanho desejado para os dots */
  opacity: 1 !important; /* Substitua '1' pela opacidade desejada para os dots */
}

/* Adiciona um estilo de fundo aos dots ativos */
.slick-dots li.slick-active button::before {
  color: black !important; /* Substitua 'red' pela cor desejada para os dots */
}

.slick-prev, .slick-next{
 display: none !important;
}

img {
  object-fit: cover;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,oBAAoB;AACtB;;;AAGA;EACE;IACE,4CAA4C;EAC9C;AACF;;AAEA;EACE,yBAAyB;EACzB,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,6BAA6B;EAC7B,YAAY;AACd;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,yBAAyB;EAC3B;AACF;;AAEA,mCAAmC;AACnC;EACE,wBAAwB,EAAE,mDAAmD;EAC7E,0BAA0B,EAAE,wDAAwD;EACpF,qBAAqB,EAAE,uDAAuD;AAChF;;AAEA,gDAAgD;AAChD;EACE,uBAAuB,EAAE,mDAAmD;AAC9E;;AAEA;CACC,wBAAwB;AACzB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".App {\n  text-align: center;\n}\n\n.App-logo {\n  height: 40vmin;\n  pointer-events: none;\n}\n \n\n@media (prefers-reduced-motion: no-preference) {\n  .App-logo {\n    animation: App-logo-spin infinite 20s linear;\n  }\n}\n\n.App-header {\n  background-color: #282c34;\n  min-height: 100vh;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  font-size: calc(10px + 2vmin);\n  color: white;\n}\n\n.App-link {\n  color: #61dafb;\n}\n\n@keyframes App-logo-spin {\n  from {\n    transform: rotate(0deg);\n  }\n  to {\n    transform: rotate(360deg);\n  }\n}\n\n/* Seletor de classe para os dots */\n.slick-dots li button::before {\n  color: silver !important; /* Substitua 'red' pela cor desejada para os dots */\n  font-size: 10px !important; /* Substitua '10px' pelo tamanho desejado para os dots */\n  opacity: 1 !important; /* Substitua '1' pela opacidade desejada para os dots */\n}\n\n/* Adiciona um estilo de fundo aos dots ativos */\n.slick-dots li.slick-active button::before {\n  color: black !important; /* Substitua 'red' pela cor desejada para os dots */\n}\n\n.slick-prev, .slick-next{\n display: none !important;\n}\n\nimg {\n  object-fit: cover;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
