import React, { createContext, useContext, useState } from 'react';

// Criar o contexto
const ClienteIdContext = createContext();

// Criar o provider
export const ClienteIdProvider = ({ children }) => {
  const [cliente_id, setCliente_id] = useState(localStorage.getItem('cliente_id'));

  const updateClienteId = (newClienteId) => {
    setCliente_id(newClienteId);
    localStorage.setItem('cliente_id', newClienteId);
  };

  return (
    <ClienteIdContext.Provider value={{ cliente_id, updateClienteId }}>
      {children}
    </ClienteIdContext.Provider>
  );
};

// Criar um hook personalizado para usar o contexto
export const useClienteId = () => {
  const context = useContext(ClienteIdContext);
  if (!context) {
    throw new Error('useClienteId deve ser usado dentro de um ClienteIdProvider');
  }
  return context;
};
