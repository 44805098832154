import React, {useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Flex, Text, Button, Input, FormControl, FormLabel, Stack, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter} from '@chakra-ui/react';
import 'react-calendar/dist/Calendar.css';
import 'react-time-picker/dist/TimePicker.css'; 
import Header from './Header';
import Swal from 'sweetalert2';
import InputMask from 'react-input-mask';
const Entrar = () => {
  const { tenant_id } = useParams();
  const navigate = useNavigate();     
  const [agendamentos, setAgendamentos] = useState(JSON.parse(localStorage.getItem('agendamentos')) || []);
  const [showModal, setShowModal] = useState(false);
  const [showResendButton, setShowResendButton] = useState(false);
  const [secondsRemaining, setSecondsRemaining] = useState(15); 
  const [cliente_id, setCliente_id] = useState(localStorage.getItem('cliente_id'));  
  const [cores, setCores] = useState([]);
  const [cadastroSimples,  setCadastroSimples] = useState(false);

  useEffect(() => {
  const fetchConfigEmpresa = async () => {
    const response = await fetch(`${apiUrl}/${tenant_id}/buscarconfigappempresa`);
    const data = await response.json();  
    setCadastroSimples(data[0].cadastrosimples);
    setCores(JSON.parse(data[0].cores)); 
  }
  fetchConfigEmpresa();
  } , []);
  
  useEffect(() => { 
    let timer;
  
    if (secondsRemaining > 0 && showResendButton === false) {
      timer = setInterval(() => {
        setSecondsRemaining((prevSeconds) => (prevSeconds > 0 ? prevSeconds - 1 : 0));
      }, 1000);
    }
  
    const resendTimer = setTimeout(() => {
      setShowResendButton(true);
      if (timer) {
        clearInterval(timer);
      }
    }, secondsRemaining * 1000);
  
    return () => {
      clearInterval(timer);
      clearTimeout(resendTimer);
    }; 
   
  }, [secondsRemaining, showResendButton]);
    
  const apiUrl = process.env.REACT_APP_API_URL; 
  const handleEnviarClick = () => { 
    const email = document.getElementById('email').value;
    const senha = document.getElementById('senha')?.value;

    // Verificar se o email é válido
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isEmailValid = emailRegex.test(email);

    if (!isEmailValid && !cadastroSimples) {
      // Email inválido, faça algo (exibir mensagem de erro, impedir o envio do formulário, etc.) 
      Swal.fire({
        title: 'Email inválido!',
        icon: 'error',
        confirmButtonText: 'Ok'
      })
      return;
    }  

    fetch(`${apiUrl}/${tenant_id}/logar`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ 
        email,
        senha, 
      }),
    })
      .then((response) => response.json())
      .then((data) => {  
        if(data.error !== undefined){
          // alert(data.error);
          Swal.fire({
            title: 'Erro!',
            text: data.error,
            icon: 'error',
            confirmButtonText: 'Ok'
          })
          return;
        }else{
          localStorage.setItem('cliente_id', data); 
          Swal.fire({
            title: 'Sucesso!',
            text: 'Login feito com sucesso!',
            icon: 'success',
            confirmButtonText: 'Ok'
          }) 
          if(agendamentos.length > 0){
            navigate(`/${tenant_id}/confirmaragendamento`);
          }else{
            navigate(`/${tenant_id}`);
          } 
        } 
        
      })
      .catch((error) => {
        // Handle any errors
        console.log('error', error);
      });
  };
 
 
  return (
    <>
    <Header agendamentos={agendamentos} />
    <Flex flexDirection="column" p={1} borderRadius="md">  
      <Box   p={1} borderRadius="md" width="100%">   
        <Text textAlign="center" fontSize="17px" fontWeight="bold"  
        color={cores.cor_textofundo || 'gray.600'} 
        >
          Faça login
        </Text>    
        <Stack spacing={4} mt={4}> 

        {cadastroSimples === true ?
          (<FormControl>
            <FormLabel
            color={cores.cor_textofundo || 'gray.600'} 
            >Whatsapp</FormLabel> 
           <InputMask 
              mask="(99) 99999-9999"  // Defina a máscara desejada
              maskChar="_"  // Caractere para substituir os espaços em branco
            >
              {() => <Input type="text" id="email" placeholder="Whatsapp" />}
            </InputMask>
          </FormControl>):
         (
            <FormControl>
            <FormLabel
            color={cores.cor_textofundo || 'gray.600'} 
            >Email</FormLabel>
            <Input type="email" id='email' placeholder="Email"  />
          </FormControl>)
          } 
         {cadastroSimples === true ?
         null
          :  <FormControl>
          <FormLabel
          color={cores.cor_textofundo || 'gray.600'} 
          >Senha</FormLabel>
          <Input type="password" id='senha' placeholder="senha" />
        </FormControl>  
          }

        </Stack> 
        <Flex  width={'100%'} textAlign="center"  direction="column"> 
                <Button
                mt={5}
                colorScheme="green"
                borderRadius="md" // Adicione bordas arredondadas
                px={8} // Adicione padding horizontal
                py={4} // Adicione padding vertical
                fontSize="14px" // Ajuste o tamanho da fonte
                _hover={{ bg: 'green.600' }} // Efeito hover   
                onClick={handleEnviarClick}
              >
                Entrar
              </Button>
        </Flex> 
        <br />
        {cadastroSimples === true ?
        null
      : <Flex   width={'100%'} textAlign="center"  direction="column"> 
      <Button  onClick={
        () => { 
          navigate(`/${tenant_id}/esqueceu`);
        }
      }>Esqueceu a senha  ?</Button> 
      
    </Flex> }
      </Box>
      <Button onClick={
            () => { 
              navigate(`/${tenant_id}/finalizaragendamento`);
            }
          } color="white"
          
          margin="auto"
          mt="3"
          background="black"
          borderRadius="md">Criar nova conta</Button>
    </Flex>
    </>
  );
};

export default Entrar;
