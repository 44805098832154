import React, {useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Flex, Text, Button, Grid, Image, keyframes} from '@chakra-ui/react';
import 'react-calendar/dist/Calendar.css';
import 'react-time-picker/dist/TimePicker.css';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';  
import Header from './Header';
import Footer from './Footer';
import Alert from './AlertPopUp';
import Swal from 'sweetalert2'
const pulse = keyframes`
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(0, 191, 98, 0.7);
  }
  70% {
    transform: scale(1.1);
    box-shadow: 0 0 0 10px rgba(0, 191, 98, 0);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(0, 191, 98, 0);
  }
`;
const ConfirmarAgendamento = () => {

  const [abreAlert, setAbreAlert] = useState(false); 
  const { tenant_id } = useParams();
  const navigate = useNavigate();     
  const [agendamentos, setAgendamentos] = useState(JSON.parse(localStorage.getItem('agendamentos')) || []);
  const handleExcluirClick = (index) => {
    const novosAgendamentos = [...agendamentos];
    novosAgendamentos.splice(index, 1); // Remove o item do array
    setAgendamentos(novosAgendamentos);
    localStorage.setItem('agendamentos', JSON.stringify(novosAgendamentos));
  };
  const [selectedBoxIndex, setSelectedBoxIndex] = useState(null);
  const [cliente_id, setCliente_id] = useState(localStorage.getItem('cliente_id'));  
  const apiUrl = process.env.REACT_APP_API_URL; 
  const apiUrlWEB = process.env.REACT_APP_API_URL_WEB;
  const [cores, setCores] = useState([]);
  const handleAdicionarServicoClick = () => {
    // Lógica para voltar para a tela de adicionar serviço
    navigate(`/${tenant_id}/`);
  };

  const [showAlert, setShowAlert] = useState(false);
  const [typeAlert, setTypeAlert] = useState('');
  const [textAlert, setTextAlert] = useState('');

  const handleShowAlert  = (param,type) => {
    setShowAlert(true);
    setTextAlert(param);
    setTypeAlert(type)
  };

  const handleCloseAlert = () => {
    setShowAlert(false);
  };
 
  useEffect(() => {
  const fetchConfigEmpresa = async () => {
    const response = await fetch(`${apiUrl}/${tenant_id}/buscarconfigappempresa`);
    const data = await response.json();   
    setCores(JSON.parse(data[0].cores)); 
  }
  fetchConfigEmpresa();
  } , []);

  const handleConfirmarClick = () => { 
 
    if(!cliente_id) {
      navigate(`/${tenant_id}/entrar`);
      return;
    }else{  
      fetch(`${apiUrl}/${tenant_id}/finalizarcadastrologado`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          cliente_id,
          agendamentos
        }),
      })
        .then((response) => response.json())
        .then((data) => {
 
          if (data[0].msg === "") { 
            localStorage.setItem('agendamentos', JSON.stringify([])); 
            Swal.fire({
              title: 'Sucesso!',
              icon: 'success',
              confirmButtonText: 'ok'
            })
            // handleShowAlert('Sucesso!','success') 
            navigate(`/${tenant_id}/meusagendamentos`); 
          } else {
            Swal.fire({
              title: 'Atenção!',
              text: data[0].msg,
              icon: 'warning',
              confirmButtonText: 'ok'
            })
            // handleShowAlert(data[0].msg,'warning') 
            localStorage.setItem('cliente_id', data[1].cliente_id); // Salvar cliente_id em localStorage
            navigate(`/${tenant_id}/confirmaragendamento`);
          } 
        })
        .catch((error) => { 
          
          console.log('error', error);
        }); 
    }  
  };

  const totalValorCliente = agendamentos.reduce((total, produto) => {
    // Converte o valor_cliente para número e adiciona ao total
    return total + Number(produto.servico.valor_cliente);
  }, 0);
 
  return (
    <>
    {showAlert && (
        <Alert
          message={textAlert}
          type={typeAlert}
          onClose={handleCloseAlert}
        />
      )}
    <Header agendamentos={agendamentos} />
    <Flex  flexDirection="column" p={1} borderRadius="md"> 
      <Flex 
        alignItems="center"   // Alinha os itens (nesse caso, o Box) verticalmente no centro
        >
        <Box  width="100%"   p={1}
          mr="4"
          textAlign="center"
          color={cores.cor_textofundo || 'black'} 
        >
          Confirme as informações<br></br> de agendamento:
        </Box>
      </Flex>
      <Box backgroundColor="silver" p={1} borderRadius="md" width="100%">

      <Flex alignItems="center" justifyContent="center">
        <Image src="/icons/1.png" alt="Carrinho" h="40px" />
        <Text marginLeft="2" fontSize="14px"  color="black">
          Carrinho
        </Text>
      </Flex>

        <Grid maxHeight={'450px'}  overflow={'auto'} templateColumns="repeat(1, 1fr)" gap={1}>
          {agendamentos.map((produto, key) => (
            <Box backgroundColor="white" p={1} borderRadius="md" key={key}   onClick={() => setSelectedBoxIndex(selectedBoxIndex === key ? null : key)}

            style={
              selectedBoxIndex === key
                ? {
                    boxShadow: '0px 0px 0px 2px #FF3D57',
                    transition: 'box-shadow 0.3s ease-in-out', 
                    marginLeft: '-60px',
                  }
                : {}
            }
            
            >
              <Flex as="tr" width="100%">
                <Flex as="td" mr={2} width={100} borderRight={'1px solid black'} textAlign="center"  direction="column">
                    <Text fontSize="11px"  fontWeight="bold" color="gray.600">{format(new Date(produto.selectedDate), 'EEEE, MMMM', { locale: ptBR })}</Text>
                    <Text fontSize="19px" fontWeight="bold" color="gray.600">{format(new Date(produto.selectedDate), 'd', { locale: ptBR })}</Text>
                    <Text fontSize="11px" fontWeight="bold" color="gray.600"> {produto.horarioSelecionado.slice(0, 5)}</Text>  
                </Flex> 
                <Flex as="td" direction="column"  justifyContent="center"> 
                  <Text fontSize="14px" fontWeight="bold" color="gray.600" style={{ textDecoration: "none" }}>
                     {produto.servico.nome.length > 18 ? produto.servico.nome.slice(0, 18) + '...' : produto.servico.nome}
                  </Text>
                  <Text fontSize="14px" fontWeight="bold" color="gray.600">
                    R$  {Number(produto.servico.valor_cliente).toLocaleString('pt-BR', { minimumFractionDigits: 2 })}
                  </Text> 
                </Flex>
                <Flex   as="td" direction="column" justifyContent="center" marginLeft="auto">
                  <Flex textAlign="center" direction="column">
                    <Text fontSize="10px" fontWeight="bold">
                      Profissional
                    </Text>
                  </Flex>
                  <Image 
                    src={`${apiUrlWEB}/uploads/${produto.professional_id.foto}`} 
                    alt="Foto"
                    boxSize="50px"
                    objectFit="cover"
                    borderRadius="md"
                    mb={2}
                    onError={(e) => {
                      e.target.src = 'https://png.pngtree.com/element_origin_min_pic/00/00/06/12575cb97a22f0f.jpg';
                    }}
                  />
                  <Flex textAlign="center" direction="column">
                    <Text fontSize="10px" fontWeight="bold">
                      {produto.professional_id.profissional}
                    </Text>
                  </Flex>
                </Flex>  
                {selectedBoxIndex === key && (
                  <Flex backgroundColor={'#FF3D57'} as="td" direction="column" justifyContent="center">
                    <Text
                      fontSize="11px"
                      p={1}
                      fontWeight="bold"
                      color="white"
                      onClick={() => handleExcluirClick(key)}
                      style={{ cursor: 'pointer' }}
                    >
                      Excluir
                    </Text>
                  </Flex>
                )}
              </Flex> 
            </Box> 
          ))}
        </Grid>

        <Grid templateColumns="repeat(1, 1fr)" gap={1}>
          <Box  p={1} borderRadius="md">
            <Flex as="tr" width="100%">
              <Flex as="td" width={100} textAlign="center"  direction="column"> 
                  <Text fontSize="23px" fontWeight="bold">
                    Total
                  </Text>
              </Flex>  
              <Flex   as="td" direction="column" justifyContent="center" marginLeft="auto">
                <Flex textAlign="center" direction="column">
                  <Text fontSize="23px" fontWeight="bold">
                    R$  {totalValorCliente.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}
                  </Text>
                </Flex>  
              </Flex>  
            </Flex> 
          </Box>
         </Grid> 
 
          <Flex  width={'100%'} textAlign="center"  direction="column"> 
                <Button
                mt={5} 
                backgroundColor={cores.cor_botoes || '#4CD4C6'}  
                color={cores.cor_texto_botoes || 'white'} 
                borderRadius="md" // Adicione bordas arredondadas
                px={8} // Adicione padding horizontal
                py={4} // Adicione padding vertical
                fontSize="14px" // Ajuste o tamanho da fonte
                _hover={{ bg: 'blue.600' }} // Efeito hover  
                onClick={handleAdicionarServicoClick}
              >
                ADICIONAR SERVIÇO
              </Button>
          </Flex>  

          <Flex  width={'100%'} textAlign="center"  direction="column"> 
              <Button
                mt={2}
                backgroundColor={'#00BF62'}
                color={'white'}
                borderRadius="md"
                px={8}
                py={4}
                fontSize="14px"
                _hover={{ bg: 'green.600' }}
                onClick={handleConfirmarClick}
                // Apply the pulse animation to the button
                animation={`${pulse} 2s infinite`}
              >
                CONFIRMAR
              </Button>
          </Flex>    

      </Box>
    </Flex> 
    </>
  );
};

export default ConfirmarAgendamento;
