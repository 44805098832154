import React, {useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Flex, Text, Button, Input, FormControl, FormLabel, Stack, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Image, Grid} from '@chakra-ui/react';
import 'react-calendar/dist/Calendar.css';
import 'react-time-picker/dist/TimePicker.css'; 
import Header from './Header';
import { format, parseISO } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { ptBR } from 'date-fns/locale'; 
import Swal from 'sweetalert2'

const Entrar = () => {
  const { tenant_id } = useParams();
  const navigate = useNavigate();     
  const [agendamentos, setAgendamentos] = useState(JSON.parse(localStorage.getItem('agendamentos')) || []);
  const [showModal, setShowModal] = useState(false);
  const [showResendButton, setShowResendButton] = useState(false);
  const [secondsRemaining, setSecondsRemaining] = useState(15); 
  const [cliente_id, setCliente_id] = useState(localStorage.getItem('cliente_id'));  
  const [isLoading, setIsLoading] = useState(false);
  const [meusagendamentos, setMeusAgendamentos] = useState(''); 
  const [selectedBoxIndex, setSelectedBoxIndex] = useState(null);
  const apiUrl = process.env.REACT_APP_API_URL; 
  const apiUrlWEB = process.env.REACT_APP_API_URL_WEB;
  const [cadastroSimples,  setCadastroSimples] = useState(false);

  useEffect(() => {
    const fetchConfigEmpresa = async () => {
      const response = await fetch(`${apiUrl}/${tenant_id}/buscarconfigappempresa`);
      const data = await response.json();  
      setCadastroSimples(data[0].cadastrosimples); 
    }
    fetchConfigEmpresa();
  } , []);

  const  ListarMeusAgendamentos = async () => {
    setIsLoading(true)
    const response = await fetch(`${apiUrl}/${tenant_id}/agendamentos/${cliente_id}`);
    const data = await response.json(); 
    setIsLoading(false)  
    
    setMeusAgendamentos(data); 
  }

  useEffect(() => {
    if(!cliente_id){
      navigate(`/${tenant_id}/entrar`);
    }
    ListarMeusAgendamentos();  
  } , []); 

  const ConfirmarAgendamento = async (agendamento_id, key) => {
    try {
      const response = await fetch(`${apiUrl}/${tenant_id}/confirmaragendamento/${agendamento_id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          agendamento_id
        }),
      });
  
      const data = await response.json(); 
      ListarMeusAgendamentos();  
    } catch (error) {
      console.log('error', error);
    }
  };
  

  const desConfirmarAgendamento = async (agendamento_id, key) => {
    try {
      await (async () => {
        const response = await fetch(`${apiUrl}/${tenant_id}/desconfirmaragendamento/${agendamento_id}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            agendamento_id
          }),
        }); 
        const data = await response.json();
        ListarMeusAgendamentos();   
      })();
    } catch (error) {
      console.log('error', error);
    }
  };

  const handleCancelarClick = async (agendamento_id) => {
    try {
      const response = await fetch(`${apiUrl}/${tenant_id}/cancelaragendamento/${agendamento_id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          agendamento_id
        }),
      });
  
      const data = await response.json(); 
      if(data.error !== undefined){
        Swal.fire({
          title: 'Opss',
          text: data.error,
          icon: 'warning',
          confirmButtonText: 'Ok'
        })
      }else{
        Swal.fire({
          title: 'Sucesso',
          text: 'Agendamento cancelado com sucesso!',
          icon: 'success',
          confirmButtonText: 'Ok'
        })
      }
      // Aguarde a resposta da requisição antes de chamar a função ListarMeusAgendamentos
       ListarMeusAgendamentos(); 
    } catch (error) {
      // Trate qualquer erro que possa ocorrer durante a requisição
      console.log('error', error);
    }
  };
  

  
  return (
    <>
    <Header agendamentos={agendamentos} />
    <Flex flexDirection="column" borderRadius="md">  
      <Box   p={1} borderRadius="md" width="100%">   
        <Text textAlign="center" fontSize="17px" fontWeight="bold" color="gray.600">
          Seus Agendamentos
        </Text>   
        <Box p={1} borderRadius="md" width="100%">   
          <Flex flexDirection="column" p={1} borderRadius="md" width="100%"> 
          <Grid maxHeight={'650px'}  overflow={'auto'} templateColumns="repeat(1, 1fr)" gap={1}> 
            {meusagendamentos.length > 0 ? (
                meusagendamentos.map((agendamento, key) => (
                  <Box backgroundColor="white" border="3px solid" p={1} borderRadius="md" key={key}  
                  onClick={(e) => 
                    {
                      e.stopPropagation(); // Impede a propagação do evento de clique para o Box

                      setSelectedBoxIndex(selectedBoxIndex === key ? null : key)

                    }}  
                  
                  style={
                    selectedBoxIndex === key && !cadastroSimples && !agendamento.payd
                      ? {
                          boxShadow: '0px 0px 0px 2px #FF3D57',
                          transition: 'box-shadow 0.3s ease-in-out', 
                          marginLeft: '-60px',
                        }
                      : {}
                  }
                  
                  >
                    <Flex as="tr" width="100%">
                      <Flex as="td" mr={2} width={100} borderRight={'1px solid black'} textAlign="center"  direction="column">
                          <Text fontSize="11px"  fontWeight="bold" color="gray.600">   
                            {format(parseISO((agendamento.data).split('T')[0]), 'EEEE, MMMM', { locale: ptBR })}</Text>
                          <Text fontSize="19px" fontWeight="bold" color="gray.600">{format(parseISO((agendamento.data).split('T')[0]), 'd', { locale: ptBR })}</Text>
                          <Text fontSize="11px" fontWeight="bold" color="gray.600"> {agendamento.inicio.slice(0, 5)}</Text> 

                          {(agendamento.confirmoumanual ===  true  || agendamento.confirmouwpp  ===  true || agendamento.confirmou_app ===  true) ? ( 
                           <Button  
                             onClick={(e) => {
                              e.stopPropagation(); // Impede a propagação do evento de clique para o Box
                              desConfirmarAgendamento(agendamento.id, key);
                            }}

                            backgroundColor="yellow"
                            color="black"
                            size="sm"
                            mt={2}
                            _hover={{ bg: 'yellow.500' }}
                            _active={{
                              bg: 'yellow.600',
                              transform: 'scale(0.95)',
                            }}
                           >
                            DESMARCAR
                           </Button> 
                          ) : (
                            <Button  
                              onClick={(e) => {
                                e.stopPropagation(); // Impede a propagação do evento de clique para o Box
                                ConfirmarAgendamento(agendamento.id, key);
                              }}

                              backgroundColor="green.500"
                              color="white"
                              size="sm"
                              mt={2}
                              _hover={{ bg: 'green.600' }}
                              _active={{
                                bg: 'green.600',
                                transform: 'scale(0.95)',
                              }}
                            >
                              CONFIRMAR
                            </Button> 
                          )} 

                      </Flex> 
                      <Flex as="td" direction="column"  justifyContent="center"> 
                        <Text fontSize="12px" fontWeight="bold" color="gray.500" style={{ textDecoration: "none" }}> 
                          {agendamento.servicos.map((servico, key) => (
                            <Text key={key}>
                              {servico.nome.length > 18 ? servico.nome.slice(0, 18) + '...' : servico.nome}
                            </Text>
                          ))
                          }
                        </Text>
                        <Text fontSize="14px" fontWeight="bold" color="gray.600"> 
                         Total R$ {agendamento.servicos.reduce((acc, servico) => acc + servico.valor_total, 0).toLocaleString('pt-BR', { minimumFractionDigits: 2 })}
                        </Text> 
                      </Flex>
                      <Flex   as="td" direction="column" justifyContent="center" marginLeft="auto">
                        <Flex textAlign="center" direction="column">
                          <Text fontSize="10px" fontWeight="bold">
                            Profissional
                          </Text>
                        </Flex>
                        <Image  
                          src={`${apiUrlWEB}/uploads/${agendamento.foto}`} 
                          alt="Foto"
                          boxSize="50px"
                          objectFit="cover"
                          borderRadius="md"
                          mb={2}
                          onError={(e) => {
                            e.target.src = 'https://png.pngtree.com/element_origin_min_pic/00/00/06/12575cb97a22f0f.jpg';
                          }}
                        />
                        <Flex textAlign="center" direction="column">
                          <Text fontSize="10px" fontWeight="bold">
                            {agendamento.profissional}
                          </Text>
                        </Flex>
                      </Flex>
                      {selectedBoxIndex === key && !cadastroSimples && !agendamento.payd && (
                        <> 
                        <Flex backgroundColor={'#FF3D57'} as="td" direction="column" justifyContent="center">
                          <Text
                            fontSize="11px"
                            p={1}
                            fontWeight="bold"
                            color="white"
                            onClick={(e) => {
                              e.stopPropagation(); 
                              handleCancelarClick(agendamento.id)
                            }}
                            style={{ cursor: 'pointer' }}
                          >
                            CANCELAR
                          </Text>
                        </Flex> 
                        </>
                      )}   
                    </Flex> 
                  </Box> 
                ))
                
              ) : (
                <Text textAlign="center" fontSize="17px" fontWeight="bold" color="gray.600"> 
                  {isLoading && 'Carregando...'}
                  {!isLoading && 'Nenhum agendamento encontrado.'}
                </Text>
              )}
          </Grid>
          </Flex> 
      </Box>
      </Box>
    </Flex>
    </>
  );
};

export default Entrar;
