import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';  
import 'react-calendar/dist/Calendar.css';  
import 'react-time-picker/dist/TimePicker.css'; 
 
const SairComponent = () => {
  const { tenant_id } = useParams();
  const navigate = useNavigate();  
  const location = useLocation();
  const [cliente_id, setCliente_id] = useState(localStorage.getItem('cliente_id'));

  useEffect(() => {
    localStorage.setItem('cliente_id', '');
    navigate(`/${tenant_id}`);
  }, []);

};

export default SairComponent;
