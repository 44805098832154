import React from 'react';
import './../css/AlertPopUp.css'; 
import { Button } from '@chakra-ui/react';

const Erro = ({ message, type, onClose }) => {
  return (
    <div className="corpo">
      <div className="error-box">
        <h1>
          Página Não Encontrada 😔
        </h1>
        <p>{message || 'Desculpe, o link que você tentou acessar não existe.'}</p>
        <p>
          Por favor, verifique o URL  
        </p> 
      </div>
    </div>
  );
};

export default Erro;
