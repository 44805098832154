import React, { useEffect, useState } from 'react';
import { Link, useFetcher, useNavigate, useParams } from 'react-router-dom';
import { Flex, Box, Heading, Spacer, Image, useEditable, Drawer, DrawerOverlay, DrawerContent, DrawerCloseButton, DrawerHeader, DrawerBody, Button, keyframes } from '@chakra-ui/react'; // Importe os componentes necessários do Chakra UI
import styled from '@emotion/styled';
import setTitle from '../services/TitleService';
import setFavicon from '../services/FaviconService';
import { CiSaveDown2 } from "react-icons/ci";
import { Helmet } from 'react-helmet';

const pulse = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.2);
    opacity: 0.8;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

const Header = (agendamentos) => { 
    const [clienteNome, setClienteNome] = useState('');
    const [empresa, setEmpresa] = useState('');  
    const [empresa_tenant_id, setEmpresatenant_id] = useState('');
    const [fotoempresa, setFotoempresa] = useState(''); 
    const [cliente_id, setCliente_id] = useState(localStorage.getItem('cliente_id'));  
    const [title, setTitle1] = useState('');
    const [cores, setCores] = useState([]);
    const [dadosapp, setDadosapp] = useState([]);

    const url = window.location.href;
    const partesDaURL = url.split('/');
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    // Verificar se há pelo menos dois elementos após a barra 
    const tenant_id = partesDaURL[3];  
    const navigate = useNavigate();  
    const apiUrl = process.env.REACT_APP_API_URL;  
    const apiUrlWEB = process.env.REACT_APP_API_URL_WEB;


    const fetchClienteId = async () => {  
        if(!cliente_id) return;
        const response = await fetch(`${apiUrl}/${tenant_id}/cliente/${cliente_id}`);
        const data = await response.json();  
        if(data.length == 0){
          localStorage.removeItem('cliente_id');
          return;
        } 

        setClienteNome((data[0].nome).split(' ')[0].toLowerCase()); 
    } 

    const fetchConfigEmpresa = async () => {
      const response = await fetch(`${apiUrl}/${tenant_id}/buscarconfigappempresa`);
      const data = await response.json();  
      setTitle1((data[0].titulo)??'seu titulo');  
      setCores(JSON.parse(data[0].cores)); 
      setDadosapp(data[0]);
    }
      

    const fetchEmpresa = async () => { 
        const response = await fetch(`${apiUrl}/${tenant_id}/empresa`);
        const data = await response.json();   
        console.log(response);
        setEmpresa(data[0].nome); 
        setEmpresatenant_id(data[0].id);
        setFotoempresa(data[0].foto);
    }  
   
    useEffect(() => {
        fetchEmpresa();   
        fetchConfigEmpresa();
    } , []); 

    useEffect(() => {
        fetchClienteId();  
    } , [cliente_id]);

    useEffect(() => {
      setTitle(title); 
    }, [title]); 

    useEffect(() => {
      if(fotoempresa == '') return;
      setFavicon(`${apiUrlWEB}/uploads/empresa/${empresa_tenant_id}/${fotoempresa}`);
      // Lembre-se de limpar o favicon quando o componente for desmontado, se necessário
      // return () => setFavicon();
    }, [empresa_tenant_id, fotoempresa]);
   
    var bodyElement = document.querySelector('body');  
    bodyElement.style.backgroundColor =  cores.cor_fundo || '#000000' ;

  
    const StyledLink = styled(Link)`
      font-size: 23px;
      color: white; /* Cor do texto */
      text-decoration: none;
      display: block;
      margin-bottom: 10px; /* Espaçamento entre os links */
      transition: color 0.3s ease-in-out; 
      &:hover {
        color: #00c851; /* Cor do texto ao passar o mouse */
      }
  `;

  const [deferredPrompt, setDeferredPrompt] = useState(null);

  useEffect(() => {
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
    });
  }, []);

  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the install prompt');
        } else {
          console.log('User dismissed the install prompt');
        }
        setDeferredPrompt(null);
      });
    }
  };

 
   
  return (
    <header> 

      <Flex p="4" style={{ marginRight: "0 !important" }} bg={cores.cor_principal || '#000000'} color="white">
        
        <Box onClick={
          () => {
            navigate(`/${tenant_id}`)
          }
        } >
          <Image  src={`${apiUrlWEB}/uploads/empresa/${empresa_tenant_id}/${fotoempresa}`}  alt="Logo" h="50px"
            onError={(e) => {
              e.target.src = 'https://l3conecta.com/storage/uploads/1b91df82-88f0-4f83-937b-1cdddb456759/istockphoto-1147544807-612x612.jpg';
            }}
          />
        </Box>   
        <Spacer />  
        {clienteNome ? 
            <Flex 
                alignItems="center"   // Alinha os itens (nesse caso, o Box) verticalmente no centro
              >
                <Box  
                  mr="4"
                  textAlign="center"
                >
                  Olá, {clienteNome}
                </Box>
            </Flex>
        : ''
        // <Box mr="4"> 
        //   <Link  to={`/${tenant_id}/entrar`}>Entrar</Link>
        // </Box>
         } 

      <Box  cursor="pointer"  pr="3"> 
          {deferredPrompt && (
            <Button 
            onClick={handleInstallClick}  
            size="md"  
            px={6}
            py={4}
            borderRadius="full"
            boxShadow="lg"
            gap={2}
            _hover={{ bg: "green.500", color:"white", boxShadow: "xl" }}
          >
            Instalar App  <CiSaveDown2 size={20} />
          </Button>
          )} 
        </Box>

        {/* Carrinho de Compras */}
        <Box onClick={
          () => {
            navigate(`/${tenant_id}/confirmaragendamento`)
          }
        }  cursor="pointer"  ml="0">
          <Image src="/icons/1.png" alt="Carrinho" h="40px" />
          <Box fontWeight={'700'} bg="yellow"   animation={`${pulse} 1s infinite`} color="black" borderRadius="full" w="20px" h="20px" fontSize="12px" textAlign="center" position="absolute" top="15px" right="94px">
            {(agendamentos?.agendamentos)?.length}
          </Box>
        </Box>
          <Box cursor="pointer"  ml="0" 
          onClick={
            () => {
              navigate(`/${tenant_id}/entrar`)
            }
          } 
          >
          <Image src="/icons/2.png" alt="Menu" h="40px" />
        </Box>
        <Box cursor="pointer" ml="0" onClick={() => setIsDrawerOpen(true)}>
          <Image src="/icons/3.png" alt="Menu" h="40px" />
        </Box>
      </Flex> 
      {/* Menu Lateral */}
      <Drawer  isOpen={isDrawerOpen} placement="right" onClose={() => setIsDrawerOpen(false)}>
        <DrawerOverlay />
        <DrawerContent
         bg={cores.cor_principal || '#000000'}
         color="white"
         display="flex"
         flexDirection="column"
         alignItems="center"
         justifyContent="center" 
        >
          <Image  src={`${apiUrlWEB}/uploads/empresa/${empresa_tenant_id}/${fotoempresa}`}  alt="Logo" w="60px"
            mx="auto"  
            my="4" 
            onError={(e) => {
              e.target.src = 'https://l3conecta.com/storage/uploads/1b91df82-88f0-4f83-937b-1cdddb456759/istockphoto-1147544807-612x612.jpg';
            }}
          />
          <DrawerCloseButton />
          {/* <DrawerHeader>Olá, {clienteNome} o que deseja?</DrawerHeader> */}
          <DrawerBody>
            <StyledLink to={`/${tenant_id}`}> 
            <Flex alignItems={'flex-end'} >
              <Image src="/icons/9.png" alt="Menu" h="40px" />Inicio
            </Flex>   
            </StyledLink>
            {!cliente_id && ( 
             <StyledLink to={`/${tenant_id}/entrar`}>
                <Flex alignItems={'flex-end'} >
                 <Image src="/icons/2.png" alt="Menu" h="40px" />Minha conta
                </Flex>
              </StyledLink>    
            )} 
            <StyledLink to={`/${tenant_id}/meusagendamentos`}>
              <Flex alignItems={'flex-end'} >
                <Image src="/icons/7.png" alt="Menu" h="40px" /> Agendamentos
              </Flex>
            </StyledLink>
            <StyledLink to={`/${tenant_id}/confirmaragendamento`}>
              <Flex alignItems={'flex-end'} >
                <Image src="/icons/1.png" alt="Menu" h="40px" /> Carrinho
              </Flex>
            </StyledLink>
            <StyledLink to={`/${tenant_id}/alterarsenha`}>
              <Flex alignItems={'flex-end'} >
              <Image src="/icons/8.png" alt="Menu" h="40px" />Alterar senha
              </Flex>
            </StyledLink>
            {cliente_id && (
              <StyledLink  to={`/${tenant_id}/sair`} style={{ textDecoration: 'underline' }}>Sair</StyledLink>
            )}
            {/* Adicione outros links conforme necessário */}
          </DrawerBody>
        </DrawerContent>
      </Drawer> 
    </header>
  );
};

export default Header;
