import React, {useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Flex, Text, Button, Input, FormControl, FormLabel, Stack, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter} from '@chakra-ui/react';
import 'react-calendar/dist/Calendar.css';
import 'react-time-picker/dist/TimePicker.css'; 
import Header from './Header';
import Swal from 'sweetalert2';
 
const Entrar = () => {
  const { tenant_id } = useParams();
  const navigate = useNavigate();     
  const [agendamentos, setAgendamentos] = useState(JSON.parse(localStorage.getItem('agendamentos')) || []);
  const [showModal, setShowModal] = useState(false);
  const [showResendButton, setShowResendButton] = useState(false);
  const [secondsRemaining, setSecondsRemaining] = useState(15); 
  const [cliente_id, setCliente_id] = useState(localStorage.getItem('cliente_id'));  
  const [isLoading, setIsLoading] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL; 


  useEffect(() => { 
    if(!cliente_id){
      navigate(`/${tenant_id}/entrar`);
    }
    let timer;
  
    if (secondsRemaining > 0 && showResendButton === false) {
      timer = setInterval(() => {
        setSecondsRemaining((prevSeconds) => (prevSeconds > 0 ? prevSeconds - 1 : 0));
      }, 1000);
    }
  
    const resendTimer = setTimeout(() => {
      setShowResendButton(true);
      if (timer) {
        clearInterval(timer);
      }
    }, secondsRemaining * 1000);
  
    return () => {
      clearInterval(timer);
      clearTimeout(resendTimer);
    }; 
   
  }, [secondsRemaining, showResendButton]);
     
  const handleEnviarClick = () => {  
    setIsLoading(true);
    const senha = document.getElementById('senha').value; 
    if((senha).replace(' ','') === ''){
      
      Swal.fire({
        title: 'Senha é obrigatória!',
        icon: 'error',
        confirmButtonText: 'Ok'
      })
      setIsLoading(false);
      return;
    }
    
   
    fetch(`${apiUrl}/${tenant_id}/atualizarsenha`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({  
        senha, 
        cliente_id,
      }),
    })
      .then((response) => response.json())
      .then((data) => {   
        if(data.error !== undefined){
          
          Swal.fire({
            title: 'Erro!',
            text: 'Ocorreu um erro. Tente novamente.!',
            icon: 'error',
            confirmButtonText: 'Ok'
          })
          setIsLoading(false);
          return;
        }else{ 
          Swal.fire({
            title: 'Senha alterada com sucesso!',
            icon: 'success',
            confirmButtonText: 'Ok'
          })
          setIsLoading(false);
          navigate(`/${tenant_id}`); 
        }
       
        
      })
      .catch((error) => {
        // Handle any errors
        console.log('error', error);
      });
  };
 
 
  return (
    <>
    <Header agendamentos={agendamentos} />
    <Flex flexDirection="column" p={1} borderRadius="md">  
      <Box   p={1} borderRadius="md" width="100%">   
        <Text textAlign="center" fontSize="17px" fontWeight="bold" color="gray.600">
          Alterar Senha
        </Text>    
        <Stack spacing={4} mt={4}>  
          <FormControl> 
            <Input type="password" id='senha' placeholder="Nova senha" />
          </FormControl> 
        </Stack> 
        <Flex  width={'100%'} textAlign="center"  direction="column"> 
                <Button
                mt={5}
                colorScheme="green"
                borderRadius="md" // Adicione bordas arredondadas
                px={8} // Adicione padding horizontal
                py={4} // Adicione padding vertical
                fontSize="14px" // Ajuste o tamanho da fonte
                _hover={{ bg: 'green.600' }} // Efeito hover   
                onClick={handleEnviarClick}
              >
                Enviar
              </Button>
        </Flex>  
      </Box>
    </Flex>
    </>
  );
};

export default Entrar;
