import React, { useEffect, useState } from 'react';
import { Link, useFetcher, useNavigate, useParams } from 'react-router-dom';
import { Flex, Box, Heading, Spacer, Image, useEditable, Drawer, DrawerOverlay, DrawerContent, DrawerCloseButton, DrawerHeader, DrawerBody, Text, Button } from '@chakra-ui/react'; // Importe os componentes necessários do Chakra UI
import styled from '@emotion/styled';
import setTitle from '../services/TitleService';
import setFavicon from '../services/FaviconService';

const Footer = (agendamentos) => { 
    const [clienteNome, setClienteNome] = useState('');
    const [empresa, setEmpresa] = useState('');  
    const [empresa_tenant_id, setEmpresatenant_id] = useState('');
    const [fotoempresa, setFotoempresa] = useState('');
    const [logradouro, setLogradouro] = useState('');
    const [title, setTitle1] = useState('');
    const [cores, setCores] = useState([]);

    const [cliente_id, setCliente_id] = useState(localStorage.getItem('cliente_id'));  
    
    const url = window.location.href;
    const partesDaURL = url.split('/');
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    // Verificar se há pelo menos dois elementos após a barra 
    const tenant_id = partesDaURL[3];  
    const navigate = useNavigate();  
    const apiUrl = process.env.REACT_APP_API_URL;  
    const apiUrlWEB = process.env.REACT_APP_API_URL_WEB;

    const fetchClienteId = async () => {  
        if(!cliente_id) return;
        const response = await fetch(`${apiUrl}/${tenant_id}/cliente/${cliente_id}`);
        const data = await response.json();  
        setClienteNome((data[0].nome).split(' ')[0].toLowerCase()); 
    }  

    const fetchConfigEmpresa = async () => {
      const response = await fetch(`${apiUrl}/${tenant_id}/buscarconfigappempresa`);
      const data = await response.json();  
      setTitle1((data[0].titulo)??'seu titulo');   
      setCores(JSON.parse(data[0].cores));
    } 

    const fetchEmpresa = async () => { 
        const response = await fetch(`${apiUrl}/${tenant_id}/empresa`);
        const data = await response.json();  
        setEmpresa(data[0].nome); 
        setEmpresatenant_id(data[0].id);
        setFotoempresa(data[0].foto);
        setLogradouro(data[0].logradouro);
    } 

    useEffect(() => {
        fetchEmpresa();  
        fetchConfigEmpresa();
    } , []); 

    useEffect(() => {
        fetchClienteId();  
    } , [cliente_id]);

    useEffect(() => {
      setTitle(title); 
    }, [title]); 

 
    useEffect(() => {
      if(fotoempresa == '') return;
      setFavicon(`${apiUrlWEB}/uploads/empresa/${empresa_tenant_id}/${fotoempresa}`);
      // Lembre-se de limpar o favicon quando o componente for desmontado, se necessário
      // return () => setFavicon();
    }, [empresa_tenant_id, fotoempresa]);
     
 
    return (
      <footer> 
       <Flex
        bg={cores.cor_principal || '#000000'} 
        color={cores.cor_textofundo || 'white'}
        justifyContent="center"
        alignItems="center"
        height="60px"
        mt="24px"
        position="fixed"
        bottom={0}
        width="100%"
        zIndex={10}
        flexDirection="column"  
      >
      <Box onClick={() => navigate(`/${tenant_id}`)} alignItems="center">
        <Image 
          backgroundColor={cores.cor_principal || '#000000'}
          borderRadius={'full'}
          p={1}
          src={`/icons/4.png`}
          alt="Logo"
          h="40px"
          style={{ marginTop: '-50px' }}
          onError={(e) => {
            e.target.src = 'https://l3conecta.com/storage/uploads/1b91df82-88f0-4f83-937b-1cdddb456759/istockphoto-1147544807-612x612.jpg';
          }}
        />
      </Box> 
      <Box alignItems="center"> 
        <Text fontSize="xs" fontWeight="bold"  
        color={cores.cor_textofundo || 'white'}
        >{logradouro}</Text>
      </Box>
    </Flex>

      </footer>
    );
};

export default Footer;
