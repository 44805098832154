// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.alert {
    padding: 15px;
    border: 1px solid;
    border-radius: 4px;
    position: absolute;
    height: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    z-index: 10;
    width: 300px;
    

  }

  /* body{
    filter: blur(5px);
  } */
  
  .success {
    background-color: #d4edda;
    border-color: #c3e6cb;
    color: #155724;
    border: 3px solid;
  }
  
  .warning {
    background-color: #fff3cd;
    border-color: #ffeeba;
    color: #856404;
    border: 3px solid;
  }
  
  .error {
    background-color: #f8d7da;
    border-color: #f5c6cb;
    color: #721c24;
    border: 3px solid;
  }

  .corpo{
    --height: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: -ms-flexbox;
    position: fixed;
    background-color: #00000061;
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-backdrop-filter: blur(2px);
            backdrop-filter: blur(2px);
    background-color: rgb(0 0 0 / 50%);
  }

  .xis{
    margin-left: auto;
  }`, "",{"version":3,"sources":["webpack://./src/css/AlertPopUp.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,iBAAiB;IACjB,kBAAkB;IAClB,kBAAkB;IAClB,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,sBAAsB;IACtB,mBAAmB;IACnB,WAAW;IACX,YAAY;;;EAGd;;EAEA;;KAEG;;EAEH;IACE,yBAAyB;IACzB,qBAAqB;IACrB,cAAc;IACd,iBAAiB;EACnB;;EAEA;IACE,yBAAyB;IACzB,qBAAqB;IACrB,cAAc;IACd,iBAAiB;EACnB;;EAEA;IACE,yBAAyB;IACzB,qBAAqB;IACrB,cAAc;IACd,iBAAiB;EACnB;;EAEA;IACE,cAAc;IACd,OAAO;IACP,QAAQ;IACR,MAAM;IACN,SAAS;IACT,oBAAoB;IACpB,eAAe;IACf,2BAA2B;IAC3B,UAAU;IACV,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,kCAA0B;YAA1B,0BAA0B;IAC1B,kCAAkC;EACpC;;EAEA;IACE,iBAAiB;EACnB","sourcesContent":[".alert {\n    padding: 15px;\n    border: 1px solid;\n    border-radius: 4px;\n    position: absolute;\n    height: auto;\n    display: flex;\n    justify-content: center;\n    flex-direction: column;\n    align-items: center;\n    z-index: 10;\n    width: 300px;\n    \n\n  }\n\n  /* body{\n    filter: blur(5px);\n  } */\n  \n  .success {\n    background-color: #d4edda;\n    border-color: #c3e6cb;\n    color: #155724;\n    border: 3px solid;\n  }\n  \n  .warning {\n    background-color: #fff3cd;\n    border-color: #ffeeba;\n    color: #856404;\n    border: 3px solid;\n  }\n  \n  .error {\n    background-color: #f8d7da;\n    border-color: #f5c6cb;\n    color: #721c24;\n    border: 3px solid;\n  }\n\n  .corpo{\n    --height: auto;\n    left: 0;\n    right: 0;\n    top: 0;\n    bottom: 0;\n    display: -ms-flexbox;\n    position: fixed;\n    background-color: #00000061;\n    z-index: 9;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    backdrop-filter: blur(2px);\n    background-color: rgb(0 0 0 / 50%);\n  }\n\n  .xis{\n    margin-left: auto;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
