import React, {useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Flex, Text, Button, Input, FormControl, FormLabel, Stack, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Drawer, DrawerOverlay, DrawerContent, DrawerHeader, DrawerBody} from '@chakra-ui/react';
import 'react-calendar/dist/Calendar.css';
import 'react-time-picker/dist/TimePicker.css'; 
import Header from './Header';
import Swal from 'sweetalert2';
 
const Entrar = () => {
  const { tenant_id } = useParams();
  const navigate = useNavigate();     
  const [agendamentos, setAgendamentos] = useState(JSON.parse(localStorage.getItem('agendamentos')) || []);
  const [showModal, setShowModal] = useState(false);
  const [showResendButton, setShowResendButton] = useState(false);
  const [secondsRemaining, setSecondsRemaining] = useState(15); 
  const [cliente_id, setCliente_id] = useState(localStorage.getItem('cliente_id'));  
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL; 
  
  useEffect(() => { 
    let timer;
  
    if (secondsRemaining > 0 && showResendButton === false) {
      timer = setInterval(() => {
        setSecondsRemaining((prevSeconds) => (prevSeconds > 0 ? prevSeconds - 1 : 0));
      }, 1000);
    }
  
    const resendTimer = setTimeout(() => {
      setShowResendButton(true);
      if (timer) {
        clearInterval(timer);
      }
    }, secondsRemaining * 1000);
  
    return () => {
      clearInterval(timer);
      clearTimeout(resendTimer);
    }; 
   
  }, [secondsRemaining, showResendButton]);
    

 
  

  const handleEnviarClick = () => { 
    const email = document.getElementById('email').value; 

    // Verificar se o email é válido
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isEmailValid = emailRegex.test(email);

    if (!isEmailValid) {
      // Email inválido, faça algo (exibir mensagem de erro, impedir o envio do formulário, etc.)
      
      Swal.fire({
        title: 'Erro!',
        text: 'Email inválido!',
        icon: 'error',
        confirmButtonText: 'Ok'
      })
      return;
    } 
    fetch(`${apiUrl}/${tenant_id}/registrarcodigologin`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ 
        email, 
      }),
    })
      .then((response) => response.json())
      .then((data) => {   
        if(data.error !== undefined){
          
          Swal.fire({
            title: 'Erro!',
            text: data.error,
            icon: 'error',
            confirmButtonText: 'Ok'
          })
          return;
        }else{
          setIsDrawerOpen(true);
        }
     
      })
      .catch((error) => { 
        // Handle any errors
        console.log('error', error);
      });
  };

  const handleEnviarClickCodigo = () => {
    setIsLoading(true);
    const codigo = document.getElementById('codigo').value;
    const email = document.getElementById('email').value;
    fetch(`${apiUrl}/${tenant_id}/entrarcomcodigo`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ 
        codigo,
        email, 
      }),
    })
      .then((response) => response.json())
      .then((data) => {  
        if(data.error !== undefined){
          Swal.fire({
            title: 'Erro!',
            text: data.error,
            icon: 'error',
            confirmButtonText: 'Ok'
          })
          setIsLoading(false);
          return;
        }else{
          localStorage.setItem('cliente_id', data); 
          
          Swal.fire({
            title: 'Sucesso!',
            text: 'Login feito com sucesso!',
            icon: 'success',
            confirmButtonText: 'Ok'
          })
          navigate(`/${tenant_id}`);
        } 
        
      })
      .catch((error) => {
        // Handle any errors
        console.log('error', error);
      });
 
  }
 
 
  return (
    <>
    <Header agendamentos={agendamentos} />
    <Flex flexDirection="column" p={1} borderRadius="md">  
      <Box   p={1} borderRadius="md" width="100%">   
        <Text textAlign="center" fontSize="17px" fontWeight="bold" color="gray.600">
          Alterar senha
        </Text>    
        <Text>você receberá um codigo para fazer login, depois de entrar altere sua senha</Text>
        <Stack spacing={4} mt={4}> 
          <FormControl>
            <FormLabel>Email</FormLabel>
            <Input type="email" id='email' placeholder="Email"  />
          </FormControl> 
        </Stack> 
        <Flex  width={'100%'} textAlign="center"  direction="column"> 
                <Button
                mt={5}
                colorScheme="green"
                borderRadius="md" // Adicione bordas arredondadas
                px={8} // Adicione padding horizontal
                py={4} // Adicione padding vertical
                fontSize="14px" // Ajuste o tamanho da fonte
                _hover={{ bg: 'green.600' }} // Efeito hover   
                onClick={handleEnviarClick}
              >
                Enviar Código
              </Button>
        </Flex>  
        
      </Box>
    </Flex>
    <Drawer isOpen={isDrawerOpen} onClose={() => setIsDrawerOpen(false)} placement="bottom">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader>Insira o Código</DrawerHeader>
        <DrawerBody>
          <FormControl> 
            <Input type="text" name='codigo' id='codigo' placeholder="Insira o código" />
          </FormControl>
         
          <Button width={'100%'}
                mt={4}   
                colorScheme="green"
                borderRadius="md" // Adicione bordas arredondadas
                px={8} // Adicione padding horizontal
                py={4} // Adicione padding vertical
                fontSize="14px" // Ajuste o tamanho da fonte
                _hover={{ bg: 'green.600' }} // Efeito hover   
                isLoading={isLoading} 
                onClick={handleEnviarClickCodigo}
              >
                Entrar
            </Button>

        </DrawerBody>
      </DrawerContent>
    </Drawer>

    </>
  );
};

export default Entrar;
