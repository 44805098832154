import React, {useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Flex, Text, Button, Input, FormControl, FormLabel, Stack, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, RadioGroup, Radio} from '@chakra-ui/react';
import 'react-calendar/dist/Calendar.css';
import 'react-time-picker/dist/TimePicker.css'; 
import Header from './Header';
import InputMask from 'react-input-mask';
import Footer from './Footer';
import Swal from 'sweetalert2';

const FinalizarAgendamento = () => {
  const { tenant_id } = useParams();
  const navigate = useNavigate();     
  const [agendamentos, setAgendamentos] = useState(JSON.parse(localStorage.getItem('agendamentos')) || []);
  const [showModal, setShowModal] = useState(false);
  const [showResendButton, setShowResendButton] = useState(false);
  const [secondsRemaining, setSecondsRemaining] = useState(15); 
  const [cliente_id, setCliente_id] = useState(localStorage.getItem('cliente_id'));  
  const apiUrl = process.env.REACT_APP_API_URL; 
  const [cadastroSimples,  setCadastroSimples] = useState(false);
  const [cores, setCores] = useState([]);
  const [value, setValue] = useState("Feminino");
  useEffect(() => {
    const fetchConfigEmpresa = async () => {
      const response = await fetch(`${apiUrl}/${tenant_id}/buscarconfigappempresa`);
      const data = await response.json();  
      setCadastroSimples(data[0].cadastrosimples);
      setCores(JSON.parse(data[0].cores)); 
    }
    fetchConfigEmpresa();
    } , []);
    
  useEffect(() => { 
    let timer;
  
    if (secondsRemaining > 0 && showResendButton === false) {
      timer = setInterval(() => {
        setSecondsRemaining((prevSeconds) => (prevSeconds > 0 ? prevSeconds - 1 : 0));
      }, 1000);
    }
  
    const resendTimer = setTimeout(() => {
      setShowResendButton(true);
      if (timer) {
        clearInterval(timer);
      }
    }, secondsRemaining * 1000);
  
    return () => {
      clearInterval(timer);
      clearTimeout(resendTimer);
    }; 
   
  }, [secondsRemaining, showResendButton]);
    
  
  const verificarCadastro = async (whatsapp) => {
    const response = await fetch(`${apiUrl}/${tenant_id}/verificarcadastro/${whatsapp}`);
    const data = await response.json();   
    console.log(data);  
  };

  const handleEnviarClick = () => {
    const nome = document.getElementById('nome').value;
    const email = document.getElementById('email')?.value;
    const senha = document.getElementById('senha')?.value;
    const wpp = document.getElementById('wpp').value;
    

    const whatsapp = wpp.replace(/\D/g, ''); // Remove todos os caracteres não numéricos
    if(whatsapp.length < 11){ 
      Swal.fire({
        title: 'Erro!',
        text: 'Whatsapp inválido',
        icon: 'error',
        confirmButtonText: 'Ok'
      })
      return;
    } 
    if(nome.length < 3){
      Swal.fire({
        title: 'Ops!',
        text: 'Favor informar o Nome e Sobrenome',
        icon: 'warning',
        confirmButtonText: 'Ok'
      })
      return;
    }
    // Verificar se o email é válido
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isEmailValid = emailRegex.test(email); 
    if (!isEmailValid  && !cadastroSimples) {
      // Email inválido, faça algo (exibir mensagem de erro, impedir o envio do formulário, etc.)
      
      Swal.fire({
        title: 'Erro!',
        text: 'Email inválido',
        icon: 'error',
        confirmButtonText: 'Ok'
      })
      return;
    }
    // Restante do código...
    // verificarCadastro(whatsapp).then((temCadastro) => {
    //   if (temCadastro) {
    //     // Usuário já tem cadastro, redirecione para a página apropriada
    //   } else {
    //     // Usuário não tem cadastro, continue com o processo de agendamento
    //     // Aqui você pode implementar a lógica para finalizar o agendamento
    //     setShowModal(true); // Call the modal by setting the state to true

    fetch(`${apiUrl}/${tenant_id}/finalizarcadastro`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        nome,
        email,
        senha,
        agendamentos,
        whatsapp,
        sexo:value
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("data", data);
        if (data[0].msg === "") {
          localStorage.setItem('cliente_id', data[1].cliente_id); // Salvar cliente_id em localStorage
          localStorage.setItem('agendamentos', JSON.stringify([])); 
           
          Swal.fire({
            title: 'Sucesso!',
            icon: 'success',
            confirmButtonText: 'Ok'
          })
          navigate(`/${tenant_id}`); 
        } else {  
          if(data[1]?.code === '-1'){ 
            Swal.fire({
              title: 'Error!',
              text: data[0].msg,
              icon: 'error',
              confirmButtonText: 'Cool'
            })
            navigate(`/${tenant_id}/entrar`); 
          }else{  
            if (data[0].msg !== "" && data[0].msg !== 'Cadastrado com Sucesso!') {
              Swal.fire({
                title: 'Error!',
                text: data[0].msg,
                icon: 'error',
                confirmButtonText: 'Cool'
              })
              localStorage.setItem('agendamentos', JSON.stringify([])); 
              navigate(`/${tenant_id}`); 
            } 
            localStorage.setItem('cliente_id', data[1].cliente_id); // Salvar cliente_id em localStorage
            navigate(`/${tenant_id}/confirmaragendamento`);
          }  
        }
      })
      .catch((error) => {
        // Handle any errors
        console.log('error', error);
      });
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
 
  const handleResendClick = () => {
    // Lógica para reenviar o código
    setShowResendButton(false);
    setSecondsRemaining(10); // Reinicia o temporizador para 10 segundos 
  };
 
  return (
    <>
    <Header agendamentos={agendamentos} />
    <Flex flexDirection="column" p={1} borderRadius="md">  
      <Box   p={1} borderRadius="md" width="100%">   
        <Text textAlign="center" fontSize="17px" fontWeight="bold"  
          color={cores.cor_textofundo || 'gray.600'} 
          >
          Faça seu cadastro para finalizar o agendamento
        </Text>  
       {cadastroSimples === true ? 
        <Stack spacing={4} mt={4}> 
        <FormControl isRequired>
        <FormLabel
          color={cores.cor_textofundo || 'gray.600'} 
          >Whatsapp</FormLabel>
          <InputMask 
            mask="(99) 99999-9999"  // Defina a máscara desejada
            maskChar="_"  // Caractere para substituir os espaços em branco
          >
            {() => <Input type="text" id="wpp" placeholder="Whatsapp" />}
          </InputMask>
        </FormControl> 
        <FormControl isRequired>
        <FormLabel
          color={cores.cor_textofundo || 'gray.600'} 
          >Nome e Sobrenome</FormLabel>
          <Input type="text" id='nome' placeholder="Nome e Sobrenome" required/>
        </FormControl> 
        <FormControl isRequired>
          <FormLabel color={cores.cor_textofundo || 'gray.600'}>Sexo</FormLabel>
          <RadioGroup onChange={setValue} value={value}>
            <Stack direction="row">
              <Radio value="Feminino"  name="sexo">Feminino</Radio>
              <Radio value="Masculino"  name="sexo">Masculino</Radio>
              <Radio value="nao-binario"  name="sexo">Não-binário</Radio>
            </Stack>
          </RadioGroup>
        </FormControl>
       
        </Stack> 
        : 
          <Stack spacing={4} mt={4}>
            <FormControl>
            <FormLabel
              color={cores.cor_textofundo || 'gray.600'} 
              >Nome Completo *</FormLabel>
              <Input type="text" id='nome' placeholder="Nome Completo" />
            </FormControl>
            <FormControl>
            <FormLabel
              color={cores.cor_textofundo || 'gray.600'} 
              >Whatsapp *</FormLabel>
              <InputMask 
                mask="(99) 99999-9999"  // Defina a máscara desejada
                maskChar="_"  // Caractere para substituir os espaços em branco
              >
                {() => <Input type="text" id="wpp" placeholder="Whatsapp" />}
              </InputMask>
            </FormControl>
            <FormControl>
            <FormLabel
              color={cores.cor_textofundo || 'gray.600'} 
              >Email *</FormLabel>
              <Input type="email" id='email' placeholder="Email"  />
            </FormControl>
            <FormControl>
            <FormLabel
              color={cores.cor_textofundo || 'gray.600'} 
              >Senha *</FormLabel>
              <Input type="password" id='senha' placeholder="senha" />
            </FormControl>  
          </Stack> 
        }

        <Flex  width={'100%'} textAlign="center"  direction="column"> 
                <Button
                mt={5}
                colorScheme="green"
                borderRadius="md" // Adicione bordas arredondadas
                px={8} // Adicione padding horizontal
                py={4} // Adicione padding vertical
                fontSize="14px" // Ajuste o tamanho da fonte
                _hover={{ bg: 'green.600' }} // Efeito hover   
                onClick={handleEnviarClick}
              >
                Finalizar Cadastro
              </Button>
        </Flex> 
          
        <Modal isOpen={showModal} onClose={handleCloseModal}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Confirmação de Código</ModalHeader>
            <ModalCloseButton />
            <ModalBody> 
              <FormControl>
              <FormLabel
              color={cores.cor_textofundo || 'gray.600'} 
              >Código de Confirmação</FormLabel>
                <Input type="text" id="confirmationCode" placeholder="Código de Confirmação" />
              </FormControl>
            </ModalBody>
            <ModalFooter> 
              <Button colorScheme="blue" mr={3} onClick={handleCloseModal}>
                Confirmar
              </Button>
              <Button variant="ghost" onClick={handleCloseModal}>
                Cancelar
              </Button>

              {showResendButton ? (
                <Button colorScheme="green" variant="ghost" onClick={handleResendClick}  >
                  Reenviar
                </Button>
                ) : (
                  <Button backgroundColor="silver" variant="ghost" fontSize={'13px'}  >
                    Reenviar em {secondsRemaining}s
                  </Button>
                )}
                  
            </ModalFooter>
          </ModalContent>
        </Modal>
     
      </Box>
    </Flex> 
    </>
  );
};

export default FinalizarAgendamento;
