import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ChakraProvider } from "@chakra-ui/react" 
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Agendar from './componentes/AgendarComponent';
import ConfirmarAgendamento from './componentes/ConfirmarAgendamento';
import FinalizarAgendamento from './componentes/FinalizarAgendamento'; 
import Header from './componentes/Header';
import { ClienteIdProvider } from './context/ClienteIdContext';
import Sair from './componentes/SairComponent';
import Entrar from './componentes/Entrar';
import Esqueceu from './componentes/Esqueceu';
import AlterarSenha from './componentes/AlterarSenha';
import MeusAgendamentos from './componentes/MeusAgendamentos';
import Alert from './componentes/AlertPopUp';
import Erro from './componentes/Erro';

ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider>
      <BrowserRouter>
        <ClienteIdProvider> 
        <Routes>
          <Route path="/:tenant_id/agendar" element={<Agendar />} />
          <Route path="/:tenant_id/confirmaragendamento" element={<ConfirmarAgendamento />} />
          <Route path="/:tenant_id/finalizaragendamento" element={<FinalizarAgendamento />} />
          <Route path="/:tenant_id" element={<App />} />
          <Route path="/:tenant_id/sair" element={<Sair />} />
          <Route path="/:tenant_id/entrar" element={<Entrar />} />
          <Route path="/:tenant_id/esqueceu" element={<Esqueceu />} />
          <Route path="/:tenant_id/alterarsenha" element={<AlterarSenha />} />
          <Route path="/:tenant_id/meusagendamentos" element={<MeusAgendamentos />} />
          <Route path="/:tenant_id/alertPopUp" element={<Alert />} />
          <Route path="/erro" element={<Erro />} />
          <Route path="/" element={<Erro />} />
        </Routes>  
        </ClienteIdProvider> 
      </BrowserRouter>
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
