import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Box, Heading, Flex, Text, Button, Grid, Image, ModalFooter, ModalBody, ModalCloseButton, ModalHeader, ModalContent, ModalOverlay, Modal, Center, keyframes } from '@chakra-ui/react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';  
import 'react-time-picker/dist/TimePicker.css';
import { format, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import Header from './Header'; 
import '../css/styles.css';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';

const pulse = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.2);
    opacity: 0.8;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

const AgendarComponent = () => {
  const { tenant_id } = useParams();
  const navigate = useNavigate();  
  const location = useLocation();
  const servico = location.state && location.state.servico;
  const [isModalOpen, setIsModalOpen] = useState(false); 
  const dataOriginal = parseISO((location.state.datainicial).split('T')[0]);
 
  const fusoHorario = 'America/Sao_Paulo'; 
  const dataFormatada = format(dataOriginal, 'yyyy-MM-dd', { timeZone: fusoHorario, locale: ptBR }); 
  
  const [selectedDate, setSelectedDate] = useState(new Date(dataFormatada+"T09:00:00.000Z"));
  const [availableTimes, setAvailableTimes] = useState([]);
  const [profissionais, setProfissionais] = useState([]);
  const [profissionaisSeach, setProfissionaisSeach] = useState([]);
  const [professional_id, setProfessional_id] = useState({});    
  const [horarioSelecionado, setHorarioSelecionado] = useState(null);
  const [agendamentos, setAgendamentos] = useState(JSON.parse(localStorage.getItem('agendamentos')) || []);
  const [data, setData] = useState([]);
  const [cores, setCores] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL; 
  const apiUrlWEB = process.env.REACT_APP_API_URL_WEB;

  const corfundo = cores.cor_fundo || "white"; // Cor padrão caso não esteja definida
  const corTextofundo  = cores.cor_textofundo || "white"; // Cor padrão caso não esteja definida
  const corTextoBotao = cores.cor_texto_botoes || "white"; // Cor padrão caso não esteja definida
  const corFundoBotao = cores.cor_botoes || "white"; // Cor padrão caso não esteja definida

  const styles = { 
    selectedDay: {
      backgroundColor: "red",  // Adicione a cor desejada para os dias selecionados
      color: "red",  // Adicione a cor desejada para o texto dos dias selecionados
    },
    nextButton: {
      color: corTextoBotao,
      backgroundColor: corFundoBotao,
      padding: "8px", 
      borderRadius: "50px",
      // Adicione mais estilos conforme necessário
    },
    prevButton: {
      color: corTextoBotao,
      backgroundColor: corFundoBotao, 
      padding: "8px", 
      borderRadius: "50px",

      // Adicione mais estilos conforme necessário
    },
  };


  const fetchConfigEmpresa = async () => {
    const response = await fetch(`${apiUrl}/${tenant_id}/buscarconfigappempresa`);
    const data = await response.json();   
    setCores(JSON.parse(data[0].cores));

  }
  
  const handleClick = (horario) => {
    setHorarioSelecionado(horario);
  };

  const handleProximoClick = () => { 
    if(professional_id.users_id ===  undefined ){  
      handleProfissionalClick();
      return; 
    }   
    const novoAgendamento = {
      servico,
      horarioSelecionado,
      selectedDate,
      professional_id,
    }; 
    // Obtém a lista atual de agendamentos do localStorage (ou uma lista vazia se não existir)
    const agendamentosExistentes = JSON.parse(localStorage.getItem('agendamentos')) || [];

    // Adiciona o novo agendamento à lista
    const novaListaAgendamentos = [...agendamentosExistentes, novoAgendamento];

    // Salva a nova lista de agendamentos no localStorage
    localStorage.setItem('agendamentos', JSON.stringify(novaListaAgendamentos));
    navigate(`/${tenant_id}/confirmaragendamento`);  
  };

  const fetchDatas = async () => { 
    try {  
      const response = await fetch(`${apiUrl}/${tenant_id}/disponibilidade/${professional_id.users_id}/${servico.servicos_id}`);
      await response.json().then((result) => { 
        setData(result);
        // Outras ações que você deseja realizar após obter os dados
      }).catch((error) => {
        console.error('Erro ao buscar datas:', error);
      });  
      // fetchAvailableTimes(); 
    } catch (error) {
      console.error('Erro ao buscar datas:', error);
    }
  };


  // Aqui você faz a requisição ao endpoint para obter os horários disponíveis
  const fetchAvailableTimes = async () => {
    try {   
      const selectedDate1 = new Date(selectedDate); 
      const ano = selectedDate1.getFullYear();
      const mes = String(selectedDate1.getMonth() + 1).padStart(2, '0');
      const dia = String(selectedDate1.getDate()).padStart(2, '0');

      const dataformatada = `${ano}-${mes}-${dia}`; 
      console.log(dataformatada); 
        
      const response = await fetch(`${apiUrl}/${tenant_id}/horarios/${dataformatada}/${professional_id.users_id}/${servico.servicos_id}`);
      const data = await response.json(); 
 
      // Filtra os horários disponíveis para excluir aqueles que já foram agendados
      const horariosDisponiveis = await data.filter((horarioDisponivel) => {
        // Verifica se a data está presente nos agendamentos
        const isHorarioDisponivel = !agendamentos.some((agendamento) => {
          const agendamentoData = new Date(agendamento.selectedDate);

          console.log("horarioDisponivel.inicio", horarioDisponivel.inicio);
          // Comparação de data
          return agendamentoData.toISOString().split('T')[0] === (horarioDisponivel.data).split('T')[0] && agendamento.horarioSelecionado === horarioDisponivel.inicio && agendamento.professional_id.users_id === professional_id.users_id;
        });

        return isHorarioDisponivel;
      }); 
 
      // Atualiza o estado com os horários disponíveis
      setAvailableTimes(horariosDisponiveis);
    } catch (error) {
      console.error('Erro ao buscar horários:', error);
    }
  }; 

  useEffect(() => {  
    seachProfissional();  
  }, [selectedDate, horarioSelecionado]);

  useEffect(() => {  
    fetchAvailableTimes();  
  }, [professional_id, selectedDate]);

  useEffect(() => {  
    fetchDatas();  
  }, [professional_id]);

  useEffect(() => {
    fetchConfigEmpresa();
  }, []);

   
  const handleDateClick = (date) => {

    setHorarioSelecionado('');
    setSelectedDate(date); 
    seachProfissional();     
  };

  const handleProfissionalClick = async () => { 
    seachProfissional(); 
    setIsModalOpen(true); 
  };
 
  
  const seachProfissional = async () => {
    try { 
      let data =  format(selectedDate, 'yyyy-MM-dd', { locale: ptBR }); 
      // Make a request to the endpoint to fetch professionals
      const response = await fetch(`${apiUrl}/${tenant_id}/profissionais/${data}/${servico.servicos_id}/${horarioSelecionado}`);
      const professionals = await response.json(); 
      setProfissionaisSeach(professionals); 
      setProfessional_id({});  
      // if (professionals.length > 0) {
      //   setProfessional_id(professionals[0]);
      // }else{
      //   setProfessional_id({});
      // }  
    } catch (error) {
      console.error('Error fetching professionals:', error);
    }
  };

  useEffect(() => {
    if (profissionaisSeach.length > 0) {
      const profissionaisDisponiveis = profissionaisSeach.filter((profissional) => {
        const isProfissionalDisponivel = !agendamentos.some((agendamento) => {
          const agendamentoData = new Date(agendamento.selectedDate).toISOString().split('T')[0];
          const horarioDisponivelData = new Date(selectedDate).toISOString().split('T')[0];
 
          return agendamentoData === horarioDisponivelData &&
                 agendamento.horarioSelecionado === horarioSelecionado &&
                 agendamento.professional_id.users_id === profissional.users_id;
        });

        return isProfissionalDisponivel;
      });  
      if(profissionaisDisponiveis.length == 1){
        setProfessional_id(profissionaisDisponiveis[0]);
      }    
      setProfissionais(profissionaisDisponiveis);
    }
  }, [profissionaisSeach, agendamentos, selectedDate, horarioSelecionado]);

 
  const handleCloseModal = () => { 
    setIsModalOpen(false);
  };
 
  
  const isDateAvailable = (date) => {
    const formattedDate = date+"T00:00:00.000Z"; 
    return data.some((item) => item.data === formattedDate); 
  };
  
  return (
    <>
    <Header agendamentos={agendamentos} />
    <Box p={1}>
      <Heading as="h2" size="xl" mb={4} textAlign="center" color={cores.cor_textofundo || "white"}>
        Agendamento
      </Heading> 
      <Flex justify="center">
        <Box>
          <Text mb={2} textAlign="center" 
          color={cores.cor_textofundo || "white"}
          >Escolha uma Data Disponível:</Text>
          <div style={{ fontSize: '19px', backgroundColor: corfundo }}> 
            <Calendar 
              onChange={handleDateClick}
              value={selectedDate}
              minDate={new Date()}
              showWeekNumbers={false}
              locale="pt-BR" 
              formatShortWeekday={(locale, date) => new Intl.DateTimeFormat(locale, { weekday: 'short' }).format(date)}
              prev2Label={null}
              next2Label={null}
              prevLabel={<span style={styles.prevButton}>Anterior</span>}
              nextLabel={<span style={styles.nextButton}>Próximo</span>}
              tileDisabled={({ date }) => {
                const formattedDate = date.toISOString().split('T')[0];
                return !isDateAvailable(formattedDate);
              }}   
              tileContent={({ date, view }) => {
                if (view === 'month' && date.toDateString() === selectedDate.toDateString()) {
                  const customStyle = {
                    backgroundColor:  corFundoBotao, 
                    color: corTextoBotao, 
                  }; 
                  return <div className='selecionadoDia' style={customStyle}>{date.getDate()}</div>;
                }
                return null;
              }}
            />
          </div> 
          <Text mt={3} mb={2} textAlign="center" color={cores.cor_textofundo || "white"}>Escolha um Horário Disponível</Text>  
          <Grid 
          templateColumns="repeat(6, 1fr)"  mb={2} 
          maxHeight={115}
          overflow="scroll"
          gap={3}>
            {availableTimes.map((horarios, index) => (
               <Box  onClick={() => handleClick(horarios.inicio)} key={index}  borderColor="gray.200" borderRadius="md" textAlign="center"
               bg={horarioSelecionado === horarios.inicio ? 'blue.500' :  corFundoBotao}  
               color={horarioSelecionado === horarios.inicio ? 'white' :  corTextoBotao} 
               height={9} 
               >
                 <Flex align="center" justifyContent="center" height="100%">
                  <Text fontSize="13px" fontWeight="bold"
                  color={corTextoBotao}
                  >
                    {horarios.inicio.slice(0, 5)} 
                  </Text>
                </Flex>
              </Box>
            ))}
          </Grid>  

        </Box> 
      </Flex> 

      <Flex alignItems="center"  borderTop={`1px solid ${corTextofundo}`} 
       backgroundColor={corfundo} 
      
      borderTopRadius="md" direction="column"  > {/* Defina a altura da coluna principal como 100% da altura da tela */}
        <Grid templateRows="1fr" templateColumns="1fr 1fr" gap={4} flex="1" p={4}> {/* 1fr para preencher igualmente, gap para adicionar espaçamento, p para adicionar padding */}
          <Box  >
            <Text>
               <Box>
                  <Flex direction="column" alignItems="center"> 
                    <Flex align="center" justify="start">
                        <Image  src={`${apiUrlWEB}/uploads/${servico.empresa_id}/${servico.foto}`}  alt="Foto" boxSize="50px" objectFit="cover" borderRadius="md" mr={4} 
                        
                        onError={(e) => {
                          e.target.src = 'https://triunfo.pe.gov.br/pm_tr430/wp-content/uploads/2018/03/sem-foto.jpg';
                        }}
                        
                        /> {/* Ajuste o tamanho da imagem e outras propriedades conforme necessário */}
                        <Flex direction="column">
                        <Text
                          fontSize="13px" 
                          whiteSpace="nowrap"
                          overflow="hidden"
                          style={{ textOverflow: 'ellipsis', maxWidth: '100%' }}
                          color={corTextofundo}
                        >
                          {servico.nome.length > 14 ? servico.nome.slice(0, 14) + '...' : servico.nome}
                        </Text>
                          <Text fontSize="12px"   color={corTextofundo}>R$ {Number(servico.valor_cliente).toLocaleString('pt-BR', { minimumFractionDigits: 2 })}</Text>
                          <Text fontSize="12px"   color={corTextofundo}>Tempo: {servico.tempo_servico}min</Text>
                        </Flex>
                    </Flex> 
                  </Flex>
                </Box>
            </Text> 
            <Text fontSize="13px" align="center"  whiteSpace="nowrap" mt={4}   color={corTextofundo}>  
            
            {`Dia ${format(selectedDate, 'dd', { locale: ptBR })}, ${format(selectedDate, 'EEEE', { locale: ptBR })}${horarioSelecionado ? ` às ${horarioSelecionado.slice(0, 5)}` : ''}`}

            </Text>

          </Box>
          <Flex
      direction="column"
      alignItems="center"
    >
    
          
          <Box  >
            <Text onClick={handleProfissionalClick} cursor="pointer"> 
            
                <Flex >
                      <Image src={`${apiUrlWEB}/uploads/${professional_id.foto}`}  alt="Foto" boxSize="50px" objectFit="cover" borderRadius="md" mr={4} 
                      onError={(e) => {
                        e.target.src = 'https://png.pngtree.com/element_origin_min_pic/00/00/06/12575cb97a22f0f.jpg';
                      }}
                      />  
                      <Flex direction="column">  
                        <Text fontSize="11px" fontWeight={700}   color={corTextofundo}>Profissional</Text>               
                        <Text fontSize="10px"   color={corTextofundo}>{
                          professional_id.profissional ? professional_id.profissional.length > 14 ? professional_id.profissional.slice(0, 14) + '...' : professional_id.profissional   : 'Selecione'
                        }</Text>  
                      </Flex> 
                </Flex> 
              </Text>
              <Modal isOpen={isModalOpen} onClose={handleCloseModal} size="full" motionPreset="slideInBottom" >
                <ModalOverlay />
                <ModalContent   backgroundColor={corfundo}> 
                  <ModalCloseButton >
                        <Text
                        fontWeight={'bold'}
                        fontSize={{ base: "2xl", md: "xl", lg: "2xl" }}
                        fontFamily={'arial'} 
                        color={corTextoBotao} 
                        >
                          X
                        </Text>
                  </ModalCloseButton>
                  <ModalBody> 

                  
                  <Heading 
                  textAlign={'center'}
                  mx="auto"  
                  my="4" 
                  p={1.5}
                  w={'170px'}
                  backgroundColor={corFundoBotao}
                  color={corTextoBotao}
                  borderRadius={100}
                  as="h1" 
                  fontSize={'16'}>
                    PROFISSIONAL 
                  </Heading> 
                  <Center>
                    <Text>
                    Selecione
                    </Text>
                  </Center>

                  {
                    profissionais.length > 0 ? '' :
                    <Center>
                      <Box 
                      onClick={() => navigate(`/${tenant_id}/confirmaragendamento`)} 
                      cursor="pointer"
                      textAlign="center"
                    >
                      <Text fontSize="20px">
                        <b>Verifique seu carrinho</b>
                      </Text>
                      <Box 
                        padding="1px" 
                        backgroundColor="blue.500" 
                        borderRadius="50px" 
                        display="flex" 
                        alignItems="center"
                        justifyContent="center"
                        position="relative"
                      >
                        <Image src="/icons/1.png" alt="Carrinho" h="40px" />
                        <Box
                          fontWeight="700"
                          bg="yellow"
                          color="black"
                          borderRadius="full"
                          w="20px"
                          h="20px"
                          fontSize="12px"
                          textAlign="center"
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          position="absolute"
                          top="0px"
                          right="80px"
                          animation={`${pulse} 1s infinite`}
                        >
                          {agendamentos?.length}
                        </Box>
                      </Box>
                    </Box>
                    </Center>
                  }
                 
                    {/* Mapeie a lista de profissionais e crie cartões para cada um */}
                    {profissionais.map((profissional, index) => (
                      <Box 
                      w={'90%'}
                      cursor="pointer"
                      mx="auto"  
                      my="4"   
                      onClick={
                        () => {
                          setProfessional_id(profissional);
                          handleCloseModal();
                        }
                      }  key={index} p={4}  borderRadius="md" mb={3}>
                          <Flex align="center">
                            <Image 
                              borderRadius="full"
                              src={`${apiUrlWEB}/uploads/${profissional.foto}`} 
                              alt="Foto" boxSize="50px" objectFit="cover" mr={4}
                              onError={(e) => {
                                e.target.src = 'https://png.pngtree.com/element_origin_min_pic/00/00/06/12575cb97a22f0f.jpg';
                              }} 
                            />
                            <Flex direction="column">
                              <Text fontSize="13px" fontWeight="bold"
                              color={corTextoBotao} 
                              >{profissional.profissional}</Text>
                            </Flex>
                          </Flex>  
                      </Box>
                    ))}
                  </ModalBody>
                  {/* <ModalFooter>
                    <Button colorScheme="blue" mr={3} onClick={handleCloseModal}>
                      Fechar
                    </Button>
                  </ModalFooter> */}
                </ModalContent>
              </Modal> 
              <Button
              mt={3}
              px={8} // Adicione padding horizontal
              py={4} // Adicione padding vertical
              fontSize="14px" // Ajuste o tamanho da fonte
              backgroundColor={corFundoBotao}
                  color={corTextoBotao}
                  borderRadius={100}
                   size="sm"
                   mb={1}
                   _hover={{ bg: 'teal.500' }}
                   _focus={{ boxShadow: 'outline' }}
              isDisabled={!horarioSelecionado} 
              onClick={handleProximoClick}
             
            >
              Próximo
            </Button>
          </Box>
          </Flex>
        </Grid>
      </Flex>
    </Box>
    </>
  );
};

export default AgendarComponent;
